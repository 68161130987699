<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2024                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 1rem !important;">

        <!-- filtros de busqueda -->
        <div class="d-flex pt-3" style="width: 100%;">
            <v-text-field ref="filtroDocumento" v-model="buscadorDocumento" label="Documento del paciente" outlined dense hide-details 
                @keypress="validarSoloLetra"
                :disabled="inProgress === true" class="me-2" style="width: 20% !important;">
            </v-text-field>

            <v-autocomplete ref="filtroTipo" v-model="buscarTipoSolicitud" label="Tipo solicitud" outlined dense hide-details :items="tiposSolicitudes" 
                :menu-props="menuPropsFiltroTipo" @click="sizeList()" @keydown="tabulador($event, 'buscarTipoSolicitud', 'filtroTipo')"
                :disabled="inProgress === true" class="me-2" style="width: 20% !important;" no-data-text="Sin resultados">
            </v-autocomplete>

            <v-autocomplete ref="filtroEstado" v-model="buscarEstado" label="Estado" outlined dense hide-details :items="tiposEstado" 
                :menu-props="{offsetY: true, maxHeight: 200}" @keydown="tabulador($event, 'buscarEstado', 'filtroEstado')"
                :disabled="inProgress === true" class="me-2" style="width: 20% !important;" no-data-text="Sin resultados">
            </v-autocomplete>

            <v-autocomplete ref="filtroAseguradora" v-model="buscadorAseguradora" label="Aseguradora" outlined dense hide-details :items="listaAseguradoras"
                :menu-props="menuPropsFiltroAseguradora" @focus="sizeList()" @keydown="tabulador($event, 'buscadorAseguradora', 'filtroAseguradora')"
                :disabled="inProgress === true" class="me-2" style="width: 20% !important;" no-data-text="Sin resultados">
            </v-autocomplete>

            <v-tooltip left :color="!filtrosInternos ? 'success' : 'primary'">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="me-2" fab :color="!filtrosInternos ? 'success' : 'primary'" v-bind="attrs" v-on="on" @mousedown.prevent="dialogoFiltros = true">
                        <v-icon>tune</v-icon>
                    </v-btn>
                </template>
                <span>{{ !filtrosInternos ? 'Agregar filtros' : 'Filtrar' }}</span>
            </v-tooltip>

            <v-tooltip v-if="filtrosEnUso" left color="error">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="me-2" fab color="error" v-bind="attrs" v-on="on" @mousedown.prevent="cleanFiltros()">
                        <v-icon>filter_list_off</v-icon>
                    </v-btn>
                </template>
                <span>Limpiar filtros</span>
            </v-tooltip>

            <!-- Campo para ver las cantidades de las solicitudes según su estado -->
            <v-tooltip bottom color="#d3d3d3">
                <template v-slot:activator="{ on, attrs }">
                    <div persistent v-bind="attrs" v-on="on" class="pa-0 ma-0 cantidad-estados">
                        <div class="div-estados pl-2 pr-2">
                            <div class="pr-1" ><span class="font-weight-black">Abiertas: </span><span>{{ abiertas }}</span></div>
                            <div class="pl-1"><span class="font-weight-black">Creadas: </span><span>{{ creadas }}</span></div>  
                        </div>
                    </div>
                </template>
                <span class="black--text">
                    <span class="font-weight-black"> Validadas: </span>{{ validadas }}<br>
                    <span class="font-weight-black"> Rechazadas: </span>{{ rechazadas }}<br>  
                    <span class="font-weight-black"> Resueltas: </span>{{ resueltas }}<br>
                    <span class="font-weight-black"> Facturadas: </span>{{ facturadas }}<br>
                    <span class="font-weight-black"> Despachadas: </span>{{ despachadas }}<br>
                    <span class="font-weight-black"> Cerradas: </span>{{ cerradas }}<br>
                    <span class="font-weight-black"> Con novedad: </span>{{ conNovedad }}
                </span>
            </v-tooltip>
        </div>

        <!-- Contenedor para ver las solicitudes de los afiliados -->
        <v-container class="container">
            <v-row justify="space-between">

                <!-- Columna para cuando hay solicitudes del afiliado -->
                <v-col v-if="mostrarSolicitudes" cols="12" sm="6" md="6" style="max-height: 72vh;" class="solicitudes">
                    <v-row>
                        <v-col cols="12" sm="12" md="12" style="height: 68vh !important; overflow-y: auto;" class="pageSolicitudes">
                            <v-card v-for="(item, index) in listaDeSolicitudes" :key="item.id" class="mb-2" :class="{ 'elevation-10': indiceTrabajando === index }" @mouseenter="seleccionarTrabajo(index)">
                                <v-card-text>
                                    <!--Número de la solicitud y alerta de revisión de datos-->
                                    <v-row v-if="item.registroAfiliado.estadoRegistro === false" no-gutters class="encabezadoCard">
                                        <v-col cols="12" md="11" align="left" class="mb-2">
                                            <v-card tile color="lighten-3" class="elevation-0" style="background-color: blanchedalmond">
                                                <span class="font-weight-black">Número solicitud: </span>
                                                <span>{{ item.idSolicitud }}</span>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="1" align="center">
                                            <v-card tile color="lighten-3" class="elevation-0 alerta">
                                                <v-tooltip right color="#FF0000">
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-btn icon style="color: red;" @click="inProgress === false ? abrirValidacionDeDatos(item) : null" v-on="on" v-bind="attrs">
                                                            <v-icon class="pulse">report</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Validar derechos</span>
                                                </v-tooltip>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <!--Número de la solicitud-->
                                    <v-row v-if="item.registroAfiliado.estadoRegistro === true" no-gutters class="encabezadoCard">
                                        <v-col cols="12" md="12" align="left" class="mb-2">
                                            <v-card tile color="lighten-3" class="elevation-0" style="background-color: lightgray">
                                                <span class="font-weight-black">Número solicitud: </span>
                                                <span>{{ item.idSolicitud }}</span>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <!--Identificación y nombre del paciente-->
                                    <v-row no-gutters class="encabezadoCard">
                                        <v-col cols="12" md="5" align="left">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <span class="font-weight-black">{{  item.registroAfiliado.tipoDocumento.descripcion }}: </span>
                                                <span> {{ item.registroAfiliado.numeroDocumento }} </span>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="7" align="left">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <span class="font-weight-black">Paciente: </span>
                                                <span> {{ item.registroAfiliado.primerNombre }} {{ item.registroAfiliado.segundoNombre }} {{ item.registroAfiliado.primerApellido }} {{ item.registroAfiliado.segundoApellido }}</span>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <!--Fecha y tipo de la solicitud-->
                                    <v-row no-gutters class="encabezadoCard">
                                        <v-col cols="12" md="6" align="left">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <span class="font-weight-black">Fecha de solicitud: </span>
                                                <span> {{ formatearFecha(item.fechaSolicitud) }}</span>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="6" align="left">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <span class="font-weight-black">Tipo de solicitud: </span>
                                                <span :class="colorTipoSolicitud(item.tipoSolicitud)"> {{ item.tipoSolicitud !== 'HOME_DELIVERY' ? 'Entrega programada' : 'Entrega a domicilio' }} </span>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <!--Aseguradora y estado de la solicitud-->
                                    <v-row no-gutters class="encabezadoCard">
                                        <v-col cols="12" md="8" align="left">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <span class="font-weight-black">Aseguradora: </span>
                                                <span> {{ item.aseguradora.nombreAseguradora }} </span>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="4" align="left">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <span class="font-weight-black">Estado: </span>
                                                <span :class="obtenerClaseEstado(item.estadoSolicitudEntrega)"> {{ traducirEstado(item.estadoSolicitudEntrega) }} </span>
                                                <v-tooltip v-if="(item.estadoSolicitudEntrega === 'NOVELTY' || item.estadoSolicitudEntrega === 'REFUSED' || item.estadoSolicitudEntrega === 'CLOSE') && item.motivos && item.motivos.length > 0" right color="#0d47a1">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon style="cursor: pointer;" color="#0d47a1" v-bind="attrs" v-on="on" @mousedown.prevent="verDetalleDelMotivo(item)">visibility</v-icon>
                                                    </template>
                                                    <span v-if="item.estadoSolicitudEntrega === 'NOVELTY'">ver novedad</span>
                                                    <span v-else>Ver motivos</span>
                                                </v-tooltip>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <!-- Correo electronico del paciente -->
                                    <v-row v-if="item.registroAfiliado && item.registroAfiliado.email !== null" no-gutters class="encabezadoCard">
                                        <v-col cols="12" md="12" align="left">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <span class="font-weight-black">Correo: </span>
                                                <span>{{ item.registroAfiliado.email.toLowerCase() }}</span>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <!--Dirección del domicilio de la solicitud o dirección de la residencia del paciente-->
                                    <v-row v-if="item.direccionEntrega !== ''" no-gutters class="encabezadoCard">
                                        <v-col cols="12" md="12" align="left">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <span v-if="item.tipoSolicitud === 'HOME_DELIVERY'" class="font-weight-black">Dirección de domicilio: </span>
                                                <span v-if="item.tipoSolicitud === 'SCHEDULED_DELIVERY'" class="font-weight-black">Dirección de residencia: </span>
                                                <span>{{ consultarTipoDirecion(item) }}</span>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <!-- Archivo de autorización entrega de medicamentos a terceros -->
                                    <v-row v-if="item.tipoSolicitud === 'HOME_DELIVERY' && item.autorizacion !== null && item.autorizacion !== ''" no-gutters class="encabezadoCard">
                                        <v-col cols="12" md="12" align="left">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <div class="d-flex align-center">
                                                    <span class="font-weight-black">Autorización entrega de medicamentos a terceros: </span>
                                                    <v-btn icon @click="inProgress === false ? verAutorizacionTerceros(item) : null">
                                                        <v-icon color="red">picture_as_pdf</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <!--Servicio de la solicitud-->
                                    <v-row v-if="item.bodegaConsultada !== undefined && item.bodegaConsultada !== null && item.listaDeBodegasSolicitud === undefined" no-gutters class="encabezadoCard">
                                        <v-col cols="12" md="12" align="left">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <span class="font-weight-black">Servicio: </span>
                                                <span> {{ item.codigoBodega }} - {{ item.bodegaConsultada }} </span>
                                                <v-tooltip right color="#0d47a1" v-if="(item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN') && item.registroAfiliado.estadoRegistro === true">
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-btn icon v-bind="attrs" v-on="on" @click="cambiarBodegaSolicitud(item)">
                                                            <v-icon color="primary">change_circle</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Cambiar</span>
                                                </v-tooltip>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <!--Seleccion de servicio de la solicitud-->
                                    <v-row v-if="item.listaDeBodegasSolicitud !== undefined && item.registroAfiliado.estadoRegistro === true" no-gutters>
                                        <v-col cols="12" md="12" align="left" class="mb-2">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <span class="font-weight-black">Selecione un servicio: </span>
                                                <v-autocomplete ref="servicioSolicitud" :items="item.listaDeBodegasSolicitud" :item-text="item => `${item.codigoBodega} - ${item.nombreBodega}`" item-value="codigoBodega"
                                                    v-model="item.bodegaSeleccionada" dense outlined color="primary" single-line hide-details @click="serviceFieldSize()" @keydown.enter.prevent="serviceFieldSize()"
                                                    @change="updateBodegaConsultada(item)" :menu-props="menuPropsServicio" :disabled="inProgress === true" no-data-text="Sin resultados" >
                                                </v-autocomplete>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <!--Tiempo en proceso de la solicitud-->
                                    <v-row v-if="(item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN' || item.estadoSolicitudEntrega === 'VALIDATED') && item.fechaSolicitud !== ''"  align="center" no-gutters class="encabezadoCard d-flex align-center">
                                        <v-col cols="12" md="4" align="left">
                                            <v-card tile color="lighten-3" class="elevation-0">
                                                <span class="font-weight-black">Tiempo en proceso: </span>
                                            </v-card>
                                        </v-col>
                                        <v-col v-if="item.estadoSolicitudEntrega === 'CREATE'" cols="12" md="8" align="left">
                                            <Timer ref="timer" style="color: #0d47a1;" :fechaSolicitud="item.fechaSolicitud" />
                                        </v-col>
                                        <v-col v-else cols="12" md="8" align="left">
                                            <Timer ref="timer" style="color: #0d47a1;" :fechaSolicitud="item.fechaSolicitud" />
                                        </v-col>
                                    </v-row>
                                    <!--Tabla con los documentos de la solicitud-->
                                    <v-row no-gutters justify="space-around">
                                        <v-col cols="12" md="12">
                                            <v-simple-table dense class="table" cols="12">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th v-if="item.registroAfiliado.documentoDigital !== '' && item.registroAfiliado.documentoDigital !== null" width="25%" class="text-center black--text font-weight-black">
                                                                DOCUMENTO
                                                            </th>
                                                            <th v-if="item.nombreFormula !== ''" width="25%" class="text-center black--text font-weight-black">
                                                                FÓRMULA
                                                            </th>
                                                            <th v-if="item.nombreAutorizacion !== ''" width="25%" class="text-center black--text font-weight-black">
                                                                AUTORIZACIÓN
                                                            </th>
                                                            <th v-if="item.nombreComprobante !== ''" width="25%" class="text-center black--text font-weight-black">
                                                                PAGO
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="tbody">
                                                        <tr v-if="item.estadoSolicitudEntrega === 'OPEN'">
                                                            <td v-if="item.registroAfiliado.documentoDigital !== '' && item.registroAfiliado.documentoDigital !== null" width="25%">
                                                                <div class="d-flex justify-center align-center">
                                                                    <v-btn icon @click="inProgress === false ? verDocumento(item) : null">
                                                                        <v-icon color="red">picture_as_pdf</v-icon>
                                                                    </v-btn>
                                                                    <div v-if="item.estadoSolicitudEntrega === 'OPEN'">
                                                                        <v-icon v-if="item.aprobadoDocumentoCorrecto === true" color="success">check_box</v-icon>
                                                                        <v-icon v-else-if="item.aprobadoDocumentoCorrecto === false" color="red">disabled_by_default</v-icon>
                                                                        <v-icon v-else :color="item.documentoCorrecto === true ? 'success' : 'red' ">{{ item.documentoCorrecto === true ?  'check_box' : 'disabled_by_default'}}</v-icon>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td v-if="item.nombreFormula !== ''" width="25%">
                                                                <div class="d-flex justify-center align-center">
                                                                    <v-btn icon @click="inProgress === false ? verFormula(item) : null">
                                                                        <v-icon color="red">picture_as_pdf</v-icon>
                                                                    </v-btn>
                                                                    <div v-if="item.estadoSolicitudEntrega === 'OPEN'">
                                                                        <v-icon v-if="item.aprobadoFormulaCorrecta === true" color="success">check_box</v-icon>
                                                                        <v-icon v-else-if="item.aprobadoFormulaCorrecta === false" color="red">disabled_by_default</v-icon>
                                                                        <v-icon v-else :color="item.formulaCorrecta === true ? 'success' : 'red' ">{{ item.formulaCorrecta === true ?  'check_box' : 'disabled_by_default'}}</v-icon>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td v-if="item.nombreAutorizacion !== ''" width="25%">
                                                                <div class="d-flex justify-center align-center">
                                                                    <v-btn icon @click="inProgress === false ? verAutorizacion(item) : null">
                                                                        <v-icon color="red">picture_as_pdf</v-icon>
                                                                    </v-btn>
                                                                    <div v-if="item.estadoSolicitudEntrega === 'OPEN'">
                                                                        <v-icon v-if="item.aprobadoAutorizacionCorrecta === true" color="success">check_box</v-icon>
                                                                        <v-icon v-else-if="item.aprobadoAutorizacionCorrecta === false" color="red">disabled_by_default</v-icon>
                                                                        <v-icon v-else :color="item.autorizacionCorrecta === true ? 'success' : 'red' ">{{ item.autorizacionCorrecta === true ?  'check_box' : 'disabled_by_default'}}</v-icon>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td v-if="item.nombreComprobante !== ''" width="25%">
                                                                <div class="d-flex justify-center align-center">
                                                                    <v-btn icon @click="inProgress === false ? verComprobantePago(item) : null">
                                                                        <v-icon color="red">picture_as_pdf</v-icon>
                                                                    </v-btn>
                                                                    <div v-if="item.estadoSolicitudEntrega === 'OPEN'">
                                                                        <v-icon v-if="item.aprobadoComprobantePago === true" color="success">check_box</v-icon>
                                                                        <v-icon v-else-if="item.aprobadoComprobantePago === false" color="red">disabled_by_default</v-icon>
                                                                        <v-icon v-else :color="item.comprobantePago === true ? 'success' : 'red' ">{{ item.comprobantePago === true ?  'check_box' : 'disabled_by_default'}}</v-icon>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr v-else>
                                                            <td v-if="item.registroAfiliado.documentoDigital !== '' && item.registroAfiliado.documentoDigital !== null" width="25%">
                                                                <div class="d-flex justify-center align-center">
                                                                    <v-btn icon @click="inProgress === false ? verDocumento(item) : null">
                                                                        <v-icon color="red">picture_as_pdf</v-icon>
                                                                    </v-btn>
                                                                    <div v-if="item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN'">
                                                                        <v-icon v-if="item.aprobadoDocumentoCorrecto === true" color="success">check_box</v-icon>
                                                                        <v-icon v-if="item.aprobadoDocumentoCorrecto === false" color="red">disabled_by_default</v-icon>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td v-if="item.nombreFormula !== ''" width="25%">
                                                                <div class="d-flex justify-center align-center">
                                                                    <v-btn icon @click="inProgress === false ? verFormula(item) : null">
                                                                        <v-icon color="red">picture_as_pdf</v-icon>
                                                                    </v-btn>
                                                                    <div v-if="item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN'">
                                                                        <v-icon v-if="item.aprobadoFormulaCorrecta === true" color="success">check_box</v-icon>
                                                                        <v-icon v-if="item.aprobadoFormulaCorrecta === false" color="red">disabled_by_default</v-icon>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td v-if="item.nombreAutorizacion !== ''" width="25%">
                                                                <div class="d-flex justify-center align-center">
                                                                    <v-btn icon @click="inProgress === false ? verAutorizacion(item) : null">
                                                                        <v-icon color="red">picture_as_pdf</v-icon>
                                                                    </v-btn>
                                                                    <div v-if="item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN'">
                                                                        <v-icon v-if="item.aprobadoAutorizacionCorrecta === true" color="success">check_box</v-icon>
                                                                        <v-icon v-if="item.aprobadoAutorizacionCorrecta === false" color="red">disabled_by_default</v-icon>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td v-if="item.nombreComprobante !== ''" width="25%">
                                                                <div class="d-flex justify-center align-center">
                                                                    <v-btn icon @click="inProgress === false ? verComprobantePago(item) : null">
                                                                        <v-icon color="red">picture_as_pdf</v-icon>
                                                                    </v-btn>
                                                                    <div v-if="item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN'">
                                                                        <v-icon v-if="item.aprobadoComprobantePago === true" color="success">check_box</v-icon>
                                                                        <v-icon v-if="item.aprobadoComprobantePago === false" color="red">disabled_by_default</v-icon>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-col>
                                    </v-row>
                                    <!--Botones de cambio de estado y mensajes informativos de cada solicitud-->
                                    <v-row class="mt-2">
                                        <v-col cols="12" md="12" align="center" v-if="item.todosAprobados === false && item.codigoBodega !== null">
                                            <v-btn small rounded color="red" @click="abrirDialogoRechazar(item)" class="white--text">
                                                RECHAZAR
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" md="12" align="center" v-else-if="item.todosAprobados === true && item.codigoBodega !== null">
                                            <v-btn small rounded color="red" @click="abrirDialogoRechazar(item)" class="white--text mr-1">RECHAZAR</v-btn>
                                            <v-btn small rounded color="#0d47a1" @click="cambiarEstado(item)" class="white--text ml-1" style="width: 96px;">APROBAR</v-btn>
                                        </v-col>
                                        <v-col cols="12" md="12" align="center" v-else-if="item.estadoSolicitudEntrega === 'CLOSE'">
                                            <span style="color: #0d47a1;">La solicitud está cerrada.</span>
                                        </v-col>
                                        <v-col cols="12" md="12" align="center" v-else-if="item.sinBodega !== undefined && item.sinBodega === true">
                                            <span style="color: #0d47a1;">{{ mensajeSinBodega }}</span>
                                        </v-col>
                                        <v-col cols="12" md="12" align="center" v-else-if="item.alerta !== undefined && item.alerta === true">
                                            <span style="color: #0d47a1;">{{ mensajeAlerta }}</span>
                                        </v-col>
                                        <v-col cols="12" md="12" align="center" v-else-if="item.visibleCount !== 0 && item.estadoSolicitudEntrega === 'OPEN'">
                                            <span style="color: #0d47a1;">Asegúrese de abrir primero los archivos incorrectos y aprobarlos o rechazarlos para continuar.</span>
                                        </v-col>
                                        <v-col cols="12" md="12" align="center" v-else-if="item.estadoSolicitudEntrega === 'CREATE'">
                                            <span style="color: #0d47a1;">Asegúrese de abrir todos los archivos y aprobarlos o rechazarlos para continuar.</span>
                                        </v-col>
                                        <v-col cols="12" md="12" align="center" v-else-if="item.estadoSolicitudEntrega === 'VALIDATED'">
                                            <v-btn small rounded color="#0d47a1" class="white--text" @click="abrirDialogoNovedad(item)">
                                                NOVEDAD
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <!--Botón para solicitar el pago de la cuota moderadora-->
                                    <!-- <v-row>
                                        <v-col cols="12" md="12" align="center">
                                            <v-btn class="white--text" color="#0d47a1" rounded small @click="dialogoSolicitarPago = true"><v-icon>attach_money</v-icon>Solicitar pago</v-btn>
                                        </v-col>
                                    </v-row> -->

                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <!--Paginación-->
                    <v-row class="pagination d-flex align-center mt-4">
                        <v-col cols="12" md="2" class="text-center pa-0">
                            <v-btn icon small :disabled="pagina === 1 || inProgress === true">
                                <v-icon @mousedown.prevent="irPrimeraPagina">first_page</v-icon>
                            </v-btn>
                            <v-btn icon small :disabled="pagina === 1 || inProgress === true">
                                <v-icon @mousedown.prevent="anteriorPagina">chevron_left</v-icon>
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="3" class="text-center pa-0">
                            <span class="pagination-text">{{ inicioItem }} - {{ finItem }} de {{ totalElements }}</span>
                        </v-col>

                        <v-col cols="12" md="2" class="text-center pa-0">
                            <v-btn icon small :disabled="pagina * itemsPorPagina >= totalElements || inProgress === true">
                                <v-icon @mousedown.prevent="siguientePagina">chevron_right</v-icon>
                            </v-btn>
                            <v-btn icon small :disabled="pagina * itemsPorPagina >= totalElements || inProgress === true">
                                <v-icon @mousedown.prevent="irUltimaPagina">last_page</v-icon>
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="5" class="pa-0">
                            <v-row class="ma-0">
                                <v-col cols="12" md="8" class="pa-0 d-flex align-center justify-center">
                                    <span style="font-size: 0.9rem;">Items por página: </span>
                                </v-col>
                                <v-col cols="12" md="3" class="pa-0 d-flex align-center justify-center">
                                    <v-select class="items" style="font-size: 0.9rem;" v-model="itemsPorPagina" :items="[10, 20, 30, 40, 50]" 
                                        dense small :disabled="inProgress === true" @change="cambiarItemsPorPagina" hide-details>
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Columna para cuando no hay solicitudes del afiliado -->
                <v-col v-if="mostrarSolicitudes === false" cols="12" sm="6" md="6" style="max-height: 72vh;">
                    <v-card class="elevation-5">
                        <v-alert dense color="success" align="center" justify="space-between">
                            <strong style="color: white">¡Bien hecho!<br> No hay solicitudes pendientes</strong>
                        </v-alert>
                        <v-img :aspect-ratio="16 / 9" v-bind:style="{'margin': 'auto', 'width': '100%'}" src="@/assets/images/Success.jpg">
                        </v-img>
                    </v-card>
                </v-col>

                <!-- Columna para el pdf seleccionado -->
                <v-col cols="12" sm="6" md="6" style="height: 74vh;" class="left_resizable boxPdf" v-bind:style="{
                    'flex-wrap': 'wrap',
                    'padding': '0 !important',
                    'padding-left': '5px !important',
                    'position': 'relative'}">
                    <v-row class="ma-0 pa-0">
                        <v-col v-if="mostarDocumento" cols="3" sm="3" md="3"></v-col>
                        <v-col cols="6" sm="6" md="6" class="pa-0" align="center">
                            <h4>{{ modalTitulo }}</h4>
                        </v-col>
                    </v-row>
                    
                    <div class="contenedor-pdf">
                        <section v-if="mostarDocumento && pdfEmbedActive" :style="{ backgroundColor: backgroundColor, top: topStyle }">
                            <v-btn icon @click="downloadPdf()"><v-icon :color="iconColor">download</v-icon></v-btn>
                        </section>
                        <embed v-if="mostarDocumento" ref="pdfViewer" class="pdf-chrome" type="application/pdf"/>
                        <div v-if="mostarDocumento === false" class="pdfClose">
                            <p v-if="!cargaDeDocumento">
                                <v-icon color="black" class="iconPdfIframe">picture_as_pdf</v-icon>
                                <br>
                                No hay seleccionado ningun documento
                            </p>
                            <p v-else>
                                <v-icon class="animation-load" color="#0d47a1">rotate_right</v-icon>
                                <br>
                                <span class="text-load">Cargando documento</span>
                            </p>
                        </div>
                    </div>
                    <div v-if="mostrarBotones && mostarDocumento" class="d-flex justify-center">
                        <v-btn small rounded color="red" class="white--text mr-1" @click="inProgress === false ? rechazarPdf() : null">Rechazar documento</v-btn>
                        <v-btn small rounded color="#0d47a1" class="white--text" @click="inProgress === false ? aprobarPdf() : null">Aprobar documento</v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <!-- dialogo para rechazar una solicitud por error en un documento -->
        <v-dialog v-model="dialogoRechazar" transition="dialog-bottom-transition" :max-width="(solicitudSeleccionada && solicitudSeleccionada.motivoGeneral) ? '38rem' : '28rem'" persistent>
            <v-card>
                <v-card-title v-if="solicitudSeleccionada && solicitudSeleccionada.motivoGeneral" class="fondoDialog d-flex align-center justify-center pa-1">
                    <span>Rechazo</span>
                </v-card-title>
                <v-card-text style="padding-top: 1.5rem;">
                    <div class="d-flex justify-center">
                        <v-autocomplete ref="motivoRechazo" v-model="motivoRechazo" :items="listaDeMotivoRechazo" outlined 
                            :menu-props="{offsetY:true, maxHeight: 280}" :readonly="inProgress === true"
                            dense label="Seleccione el motivo" no-data-text="Sin resultados" :rules="!motivoDeDocumento ? rules.obligatorio : []">
                            <template v-slot:label>
                                Seleccione el motivo <span class="error--text">*</span>
                            </template>
                        </v-autocomplete>
                    </div>

                    <!-- Campo para la observación del rechazo -->
                    <div v-if="solicitudSeleccionada && solicitudSeleccionada.motivoGeneral">
                        <v-row class="ma-0">
                            <v-col cols="12" md="12" class="ma-0 pa-0">
                                <div class="d-flex align-items-center">
                                    <v-textarea id="observacionId" v-model="observacionRechazo" label="Observación" hide-details
                                        rows="2" no-resize dense outlined :maxlength="maximoCaracteres" :readonly="inProgress === true">
                                    </v-textarea>
                                </div>
                                <div class="d-flex justify-end mr-4" style="font-size: smaller;">
                                    <span>{{ contadorCaracteres }} / {{ maximoCaracteres }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>

                    <!-- Subir documento rechazo  -->
                    <div v-if="solicitudSeleccionada && solicitudSeleccionada.motivoGeneral" class="d-flex align-center justify-start pa-0">
                        <v-row class="ma-0">
                            <v-col cols="12" md="12" class="ma-0 pa-0">
                                <v-file-input class="file text-truncate" accept=".pdf, .jpg, .png, .jpeg, .tiff" ref="adjuntoRechazoFile" 
                                    prefix="Seleccione archivo" prepend-icon :clearable="true" :disabled="inProgress === true"
                                    outlined dense :error-messages="formatoIncorrecto.adjuntoRechazo" @change="cargarDocumento('adjuntoRechazo')" 
                                    @click:clear="borrarDocumento('adjuntoRechazo')">
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- <template>
                        <v-container>
                            <v-row>
                                <v-col cols="12" class="pt-0">
                                    <v-list v-model="motivosSeleccionados" dense class="refused">
                                        <v-list-item v-for="(motivo, index) in listaDeMotivoRechazo" :key="index" dense class="pa-0">
                                            <v-list-item-action class="ma-0">
                                                <v-checkbox v-model="motivo.selected" :label="motivo.text" :value="motivo.value" @change="updateSelectedMotivos"></v-checkbox>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template> -->
                    <div v-if="inProgress === false" class="d-flex justify-end">
                        <v-btn color="error" text @click="cerrarDialogoRechazar()">CANCELAR</v-btn>
                        <v-btn color="success" depressed 
                            @click="(solicitudSeleccionada && solicitudSeleccionada.motivoGeneral) ? rechazar() : confirmarRechazoPdf()" 
                            :disabled="!motivoDeDocumento && (motivoRechazo === null || motivoRechazo === '')">
                            GUARDAR
                        </v-btn>
                    </div>
                    <div v-else class="d-flex justify-center">
                        <v-icon class="rotate-animation" color="#0d47a1" large>rotate_right</v-icon>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!--Ver datos de validación -->
        <v-dialog v-model="dialogoValidacionDeDatos" transition="dialog-bottom-transition" max-width="25rem" persistent>
            <v-card>
                <v-card-title class="fondoDialog d-flex align-center justify-space-between">
                    <span class="text-h6">Validación de datos</span>
                    <v-icon color="white" @click="dialogoValidacionDeDatos = false" :disabled="inProgress === true">close</v-icon>
                </v-card-title>
                <v-card-text style="padding-top: 1.5rem; color: black;">
                    <div>
                        <span class="font-weight-bold">Nombres: </span><span>{{ validarNombres }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">Apellidos: </span><span>{{ validarApellidos }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">Identificación: </span><span>{{ validaridentificacion }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">Género: </span><span>{{ formatoGenero(validarGenero) }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">Aseguradora: </span><span>{{ validarAseguradora }}</span>
                    </div>
                    <div>
                        <span class="font-weight-bold">Régimen: </span><span>{{ validarRegimen }}</span>
                    </div>
                    
                    <div v-if="inProgress === false" class="d-flex justify-center pt-6">
                        <v-btn color="red" style="color: white;" class="mr-1" @click="abrirValidacionDePaciente()">RECHAZAR</v-btn>
                        <v-btn color="success" class="ml-1" depressed @click="aprobarValidacionDeDatos()">APROBAR</v-btn>
                    </div>
                    <div v-else class="d-flex justify-center">
                        <v-icon class="rotate-animation" color="#0d47a1" large>rotate_right</v-icon>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!--Ingresar motivo de rechazo para que el afiliado no vuelva a hacer solicitudes -->
        <v-dialog v-model="dialogoValidacionPaciente" transition="dialog-bottom-transition" max-width="27rem" persistent>
            <v-card>
                <v-card-text style="padding-top: 1.5rem;">
                    <validation-observer ref="observerRechazarPaciente" v-slot="{ invalid }">
                        <div class="d-flex justify-center">
                            <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, }" ref="motivoRechazo" style="width: 100%">
                                <v-autocomplete id="motivoRechazo" v-model="motivoRechazo" :items="listaDeMotivoRechazo" outlined 
                                    :menu-props="{offsetY:true, maxHeight: 280, maxWidth: 336}" :error-messages="errors" :readonly="inProgress === true"
                                    dense label="Seleccione el motivo de rechazo" no-data-text="Sin resultados">
                                </v-autocomplete>
                            </validation-provider>
                        </div>
                        
                        <div class="d-flex justify-end">
                            <v-btn color="error" text @click="cerrarDialogoRechazarPaciente()">CANCELAR</v-btn>
                            <v-btn color="success" depressed @click="rechazarValidacionDeDatos()" :disabled="invalid">CONFIRMAR</v-btn>
                        </div>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>
        
        <!-- dialogo para solicitar el pago -->
        <v-dialog v-model="dialogoSolicitarPago" transition="dialog-bottom-transition" max-width="20rem" persistent>
            <v-card>
                <v-card-text style="padding-top: 1.5rem;">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, }">
                            <div class="d-flex justify-center">
                                <v-text-field id="valorPago" v-model="valorPago" style="width: 21.3rem;"
                                    label="Ingrese el valor del copago" dense outlined @input="numeroFormateado($event, 'valorPago')"
                                    @keypress="validarLetra($event)" :error-messages="errors">
                                </v-text-field>
                            </div>
                        </validation-provider>

                        <div class="d-flex justify-end">
                            <v-btn class="mr-2" rounded small outlined color="#0d47a1" @click="dialogoSolicitarPago = false">CANCELAR</v-btn>
                            <v-btn rounded small class="white--text" color="#0d47a1" depressed :disabled="invalid">CONFIRMAR</v-btn>
                        </div>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Contenedor para mostrar los mensajes de error -->
        <div v-if="mostrarNotificacion" :class="['notificacion', tipoNotificacion]">
            <span><v-icon :color="tipoNotificacionColor" :class="iconoNotificacionClase" class="rotate-animation-notification size">{{ iconoNotificacion }}</v-icon></span>
            <span>{{ mensajeNotificacion }}</span>
        </div>

        <!-- Mensaje de alerta para cuando hay una validación en proceso -->
        <template>
            <div v-if="validateProgress" class="overlay">
            <v-alert class="notificationValidation" persistent transition="dialog-bottom-transition">
                <v-icon class="d-flex justify-center rotate-animation" color="white" large>rotate_right</v-icon>
                <span class="d-flex justify-center">Validación en proceso</span>
            </v-alert>
            </div>
        </template>
        
        <!-- Dialogo para usar los filtros Número solicitud y Servicio -->
        <v-dialog v-model="dialogoFiltros" persistent transition="dialog-bottom-transition" max-width="35rem">
            <v-card>
                <v-card-title class="d-flex justify-center encabezado">
                    <span class="text-h6">Filtros</span>
                </v-card-title>
                <v-card-text class="pt-4">
                <div class="mb-4 d-flex">
                    <v-text-field v-model="buscadorIdsolicitud" label="Número solicitud" outlined dense hide-details 
                        @keypress="validarSoloLetra" :disabled="inProgress === true" class="me-2" style="width: 50% !important;">
                    </v-text-field>

                    <v-autocomplete ref="filtroServicio" v-model="buscadorServicio" label="Servicio" outlined dense hide-details :items="listaServicios"
                        :menu-props="menuPropsFiltroServicio" @focus="sizeList()" @keydown="tabulador($event, 'buscadorServicio', 'filtroServicio')"
                        :disabled="inProgress === true" style="width: 50% !important;" no-data-text="Sin resultados">
                    </v-autocomplete>
                </div>
                <div class="d-flex justify-end">
                    <v-btn text color="error" class="mr-2" @click="cleanFiltrosInternos()">LIMPIAR</v-btn>
                    <v-btn color="success" :disabled="buscadorIdsolicitud === null && buscadorServicio === null" @click="listarSolicitudes()">APLICAR</v-btn>
                </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- dialogo para elegir una novedad de una solicitud -->
        <v-dialog v-model="dialogoNovedad" transition="dialog-bottom-transition" max-width="38rem" persistent>
            <v-card>
                <v-card-title class="fondoDialog d-flex align-center justify-center pa-1">
                    <span>Novedad</span>
                </v-card-title>
                <v-card-text style="padding-top: 1.5rem;">
                    <div class="d-flex justify-center">
                        <v-autocomplete ref="motivoNovedad" v-model="motivoNovedad" :items="motivosNovedad" outlined 
                            :menu-props="{offsetY:true, maxHeight: 280}" :readonly="inProgress === true"
                            dense label="Seleccione el motivo" :rules="rules.obligatorio" no-data-text="Sin resultados">
                        </v-autocomplete>
                    </div>
                    <!-- Subir documento novedada  -->
                    <div class="d-flex align-center justify-start pa-0">
                        <v-row class="ma-0">
                            <v-col cols="12" md="12" class="ma-0 pa-0">
                                <v-file-input class="file text-truncate" accept=".pdf, .jpg, .png, .jpeg, .tiff" ref="adjuntoFile" 
                                    prefix="Adjuntar novedad" prepend-icon :clearable="true"
                                    outlined dense :error-messages="formatoIncorrecto.adjunto" @change="cargarDocumento('adjunto')" 
                                    @click:clear="borrarDocumento('adjunto')">
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="inProgress === false" class="d-flex justify-end">
                        <v-btn color="error" text @click="cerrarDialogoNovedad()">CANCELAR</v-btn>
                        <v-btn color="success" depressed @click="novedad()" :disabled="motivoNovedad === null || motivoNovedad === ''">GUARDAR</v-btn>
                    </div>
                    <div v-else class="d-flex justify-center">
                        <v-icon class="rotate-animation" color="#0d47a1" large>rotate_right</v-icon>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- dialogo para ver el detalle del motivo de rechazo, novedad o cancelación de la solicitud -->
        <v-dialog v-model="dialogoDetalleDelMotivo" transition="dialog-bottom-transition" max-width="28rem" persistent>
            <v-card>
                <v-card-text class="mt-0 pa-3 pr-0 pt-0">
                    <v-row no-gutters>
                        <v-col cols="12" md="11" class="pt-3">
                            <ul v-if="mensajeDelDetalleDelMotivo.length > 0">
                                <li v-for="(motivo, index) in mensajeDelDetalleDelMotivo" :key="index" style="color: black">
                                    {{ motivo.descripcion }}
                                    <span v-if="motivo.observacion && motivo.observacion !== ''"> - {{ motivo.observacion }}</span>
                                </li>
                            </ul>
                            <span v-else style="color: black">No hay motivos disponibles.</span>
                        </v-col>
                        <v-col cols="12" md="1">
                            <v-icon class="ma-1 mr-0 mt-2" @click="cerrarDialogoDetalleDelMotivo" color="black">close</v-icon>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

         <!-- Mensaje de alerta para cuando se desea abrir un documento pero hay que validar primero los derechos del paciente o no se ha selecionado un servicio -->
         <template>
            <div v-if="warningValidation" class="overlay-derechos">
            <v-alert class="notification-derechos" persistent transition="dialog-bottom-transition">
                <span class="d-flex justify-center white--text">{{ mensajeDeWarning }}</span>
            </v-alert>
            </div>
        </template>

    </v-main>

</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapMutations, mapState } from 'vuex';
import { Role } from '@/router/role.js';
import "jquery-ui/ui/widgets/resizable";
import $ from "jquery";
import moment from 'moment';
import Timer from "@/components/Timer.vue";

extend('obligatorio', {
    ...required,
    message: 'Campo obligatorio'
});

const debounce = function debounce(fn, delay) {
    let timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        let args = arguments;
        let that = this;
        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    };
};
export default {
    name: 'Solicitud',
    components: {
        ValidationObserver,
        ValidationProvider,
        Timer,
    },

    data() {
        return {
            roles: Role,
            userRoles: {},
            usuario: '',
            buscadorIdsolicitud: null,
            buscadorDocumento: null,
            buscarTipoSolicitud: null,
            buscarEstado: null,
            buscadorAseguradora: null,
            buscadorServicio: null,
            tiposSolicitudes: [
                { text: 'Entrega programada', value: 'SCHEDULED_DELIVERY' },
                { text: 'Entrega a domicilio', value: 'HOME_DELIVERY' },
            ],
            tiposEstado: [
                { text: 'Creada', value: 'CREATE' },
                { text: 'Abierta', value: 'OPEN' },
                { text: 'Validada', value: 'VALIDATED' },
                { text: 'Facturada', value: 'INVOICED' },
                { text: 'Despachada', value: 'DISPATCHED' },
                { text: 'Resuelta', value: 'RESOLVED' },
                { text: 'Rechazada', value: 'REFUSED' },
                { text: 'Cerrada', value: 'CLOSE' },
                { text: 'Novedad', value: 'NOVELTY' },
            ],
            tiposEstadoFiltrados: [],
            dialog: false,
            estado: false,
            headersSolicitudes: [
                { text: "FECHA", align: "center", sortable: false, width: "2rem", value: 'fecha' },
                { text: "AFILIADO", sortable: false, width: "10rem", value: 'afiliado' },
                { text: "SOLICITUD", sortable: false, width: "5rem", value: 'tipoSolicitud' },
                { text: "ESTADO", align: "center", sortable: false, width: "7rem", value: 'estado' },
                { text: "DOCUMENTO DE IDENTIDAD", sortable: false, width: "11rem", value: 'documentoIdentidad' },
                { text: "FÓRMULA MÉDICA", sortable: false, width: "9rem", value: 'formulaMedica' },
                { text: "AUTORIZACIÓN MÉDICA", sortable: false, width: "10rem", value: 'autorizacionMedica' },
                { text: "TRANSCRIBIR", sortable: false, width: "10rem", value: 'trascribir' },
            ],
            listaDeSolicitudes: [],
            pagina: 1,
            itemsPorPagina: 10,
            totalElements: 0,
            footerPropslistaSolicitud: {
                'items-per-page-options': [10, 20, 30, 40, 50],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            },
            solicitudSeleccionada: {},
            mensajeEstado: '',
            mostarDocumento: false,
            modalTitulo: '',
            pdfBase64: null,
            dialogoRechazar: false,
            motivoRechazo: null,
            maximoCaracteres: 500,
            caracteresDobles: ["𝕏"],
            checkboxDocumento: true,
            checkboFormula: true,
            checkboxAutorizacion: true,
            checkboxComprobantePago: true,
            nuevoEstado: '',
            rechazados: false,
            aprobados: false,
            todosLosArchivos: false,
            mostrarBotones: false,
            mostrarSolicitudes: true,
            pdfVisible: {
                documento: false,
                formula: false,
                autorizacion: false,
                comprobantePago: false,
            },
            pdfAprobado: {
                documento: false,
                formula: false,
                autorizacion: false,
                comprobantePago: false,
            },
            itemActual: null,
            mensajeAlerta: "",
            dialogoValidacionDeDatos: false,
            validarNombres: '',
            validarApellidos: '',
            validaridentificacion: '',
            validarAseguradora: '',
            validarGenero: '',
            validarRegimen: '',
            validarNivel: '',
            dialogoValidacionPaciente: false,
            validarIdKeycloak: '',
            validarIdRegistro: '',
            validarEstadoRegistro: '',
            validaridEmpresa: '',
            mensajeSinBodega: '',
            indiceTrabajando: null,
            listaDeMotivoRechazo: [],
            listaServicios: [],
            dialogoSolicitarPago: false,
            valorPago: null,
            rechazoDePaciente: false,
            tiposVias: ["AVENIDA CALLE", "AVENIDA CARRERA", "AUTOPISTA", "AVENIDA", "ANILLO VIAL", "CALLE", "CALLEJÓN", "CARRERA", "CARRETERA", "CIRCUNVALAR", "DIAGONAL", "TRANSVERSAL"],
            mostrarNotificacion: false,
            mensajeNotificacion: "",
            tipoNotificacion: "",
            tipoNotificacionColor: "",
            iconoNotificacion: "",
            iconoNotificacionClase: "",
            motivosSeleccionados: [],
            inProgress: false,
            validateProgress: false,
            menuPropsFiltroTipo: { offsetY: true, maxHeight: 200, maxWidth: null },
            menuPropsFiltroServicio: { offsetY: true, maxHeight: 200, maxWidth: null },
            menuPropsServicio: { offsetY: true, maxHeight: 160, maxWidth: null },
            menuPropsFiltroAseguradora: { offsetY: true, maxHeight: 160, maxWidth: null },
            dialogoFiltros: false,
            abiertas: 0,
            creadas: 0,
            validadas: 0,
            rechazadas: 0,
            resueltas: 0,
            facturadas: 0,
            despachadas: 0,
            cerradas: 0,
            conNovedad: 0,
            filtrosInternos: false,
            motivosDerechos: [],
            motivosDocumento: [],
            motivosFormula: [],
            motivosAutorizacion: [],
            motivosPago: [],
            motivosGenerales: [],
            motivosNovedad: [],
            dialogoNovedad: false,
            motivoNovedad: null,
            rules: {
                obligatorio: [
                v => !!v || "Campo obligatorio",
                ],
            },
            solicitudNovedad: {},
            documento: {
                adjunto: null,
                adjuntoRechazo: null,
            },
            formatoIncorrecto: {
                adjunto: null,
                adjuntoRechazo: null,
            },
            observacionRechazo: null,
            motivoRechazoAll: [],
            dialogoDetalleDelMotivo: false,
            mensajeDelDetalleDelMotivo: [],
            warningValidation: false,
            mensajeDeWarning: '',
            cargaDeDocumento: false,
            motivoDeDocumento: false,
            nombreArchivoPdf: '',
            pdfEmbedActive: false,
        }
    },

    // Se inicializa la variable userRoles con los roles del usuario autenticado
    created() {
        this.userRoles = this.auth.roles;
        this.listarSolicitudes();
        this.sizeList();
        this.listarServicios();
        this.listarAseguradoras();
        this.listarMotivoRechazo();
    },

    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
        // Calcula el índice del primer elemento de la página actual
        inicioItem() {
            return (this.pagina - 1) * this.itemsPorPagina + 1;
        },
        // Calcula el índice del último elemento de la página actual
        finItem() {
            const fin = this.pagina * this.itemsPorPagina;
            return fin > this.totalElements ? this.totalElements : fin;
        },
        // Contador de caracteres para el campo observacionRechazo
        contadorCaracteres() {
            let contador = 0;
            const texto = this.observacionRechazo;

            if (texto) {
                for (let i = 0; i < texto.length; i++) {
                const char = texto[i];

                    if (this.caracteresDobles.includes(char)) {
                        contador += 2;
                    } else {
                        contador += 1;
                    }
                }
            }
            return contador;
        },
        // Verificador de filtros en uso
        filtrosEnUso() {
            let numDocu = this.buscadorDocumento === null ? '' : this.buscadorDocumento;
            let tipSoli = this.buscarTipoSolicitud !== null ? this.buscarTipoSolicitud : '';
            let estado = this.buscarEstado === null ? '' : this.buscarEstado;
            let aseguradora = this.buscadorAseguradora === null ? '' : this.buscadorAseguradora;
            return numDocu !== '' || tipSoli !== '' || estado !== '' || aseguradora !== '' || this.filtrosInternos === true;
        },
    },

    watch: {
        'buscadorDocumento': debounce(function () {
            this.pagina = 1;
            this.listarSolicitudes();
        }, 300),
        'buscarTipoSolicitud': function () {
            this.pagina = 1;
            this.listarSolicitudes();
        },
        'buscarEstado': function () {
            this.pagina = 1;
            this.listarSolicitudes();
        },
        'buscadorAseguradora': function () {
            this.pagina = 1;
            this.listarSolicitudes();
        },
        'itemsPorPagina': function () {
            this.pagina = 1;
            this.listarSolicitudes();
            this.$nextTick(() => {
                this.goTo();
                this.$refs.timer.forEach(timer => {
                    timer.reset();
                });
            });
        },
        'totalElements': function () {
            this.pagina = 1;
            this.listarSolicitudes();
            this.$nextTick(() => {
                this.goTo();
                this.$refs.timer.forEach(timer => {
                    timer.reset();
                });
            });
        },
        'pagina': function () {
            this.$nextTick(() => {
                this.goTo();
                this.$refs.timer.forEach(timer => {
                    timer.reset();
                });
            });
        },
        'buscadorIdsolicitud': function(newValue) {
            if (newValue === '') {
                this.buscadorIdsolicitud = null
            }
        },
        'warningValidation': function(newValue) {
            if (newValue === true) {
                setTimeout(() => {
                    this.warningValidation = false;
                }, 2000);
                setTimeout(() => {
                    this.mensajeDeWarning = '';
                }, 2500);
            }
        },
    },

    mounted() {
        this.usuario = this.auth.username.toUpperCase();
        window.setTimeout(function () {
            $(".left_resizable").resizable({
                resize: function (event, ui) {
                    ui.size.width = ui.originalSize.width;
                },
            });
        }, 1500);
        const browser = this.getBrowser();
        if (browser === 'firefox') {
            this.backgroundColor = '#f9f9fa'; 
            this.topStyle = '-4px';
            this.iconColor = 'black';
        } else {
            this.backgroundColor = '#323639';
            this.topStyle = '10px';
            this.iconColor = 'white';
        }
    },

    methods: {
        ...mapMutations(["updateAuth", "hideNotify", "showSuccess", "showBusy", "hideBusy",]),
        /** Método para restablecer el scroll */
        goTo() {
            this.$vuetify.goTo(
                '.solicitudes',{
                    offset: 100,
                    container: '.pageSolicitudes'
                }
            )
        },
        /**
         * Cuenta el número de campos visibles que necesitan corrección en una solicitud específica, estableciendo el valor en visibleCount.
         */
        todosLosTdVisibles(item) {
            const visibleCount = [
                { value: item.registroAfiliado.documentoDigital, correct: !item.documentoCorrecto },
                { value: item.nombreFormula, correct: !item.formulaCorrecta },
                { value: item.nombreAutorizacion, correct: !item.autorizacionCorrecta },
                { value: item.nombreComprobante, correct: !item.comprobantePago }
            ].filter(td => this.tdVisible(td)).length;
            item.visibleCount = visibleCount;
            return visibleCount === 4;
        },
        /**
         * Verifica si un campo es visible y necesita corrección en una solicitud específica.
         */
        tdVisible(td) {
            return td.value !== '' && td.value !== null && td.correct;
        },
        /**
         * Verifica si todos los métodos relacionados con la visualización de los documentos han sido ejecutados para un ítem dado.
         * Si todos los métodos han sido ejecutados, actualiza el estado del ítem para reflejar si todos los documentos están aprobados.
         * 
         * @param {Object} item - El ítem para el cual se verifican los métodos ejecutados.
         * @returns {boolean} - Devuelve true si todos los métodos han sido ejecutados, de lo contrario devuelve false.
         */
        metodosEjecutados(item) {
            const tdVisibles = [
                { nombre: 'registroAfiliado.documentoDigital', ejecutado: item.verDocumentoEjecutado },
                { nombre: 'nombreFormula', ejecutado: item.verFormulaEjecutado },
                { nombre: 'nombreAutorizacion', ejecutado: item.verAutorizacionEjecutado },
                { nombre: 'nombreComprobante', ejecutado: item.verComprobantePagoEjecutado },
            ];
            const elementosPresentes = tdVisibles.filter(td => td.ejecutado !== undefined && td.ejecutado !== null);
            if (elementosPresentes.length === item.visibleCount) {
                const aprobados = [
                    { nombre: 'aprobadoDocumentoCorrecto', estado: item.aprobadoDocumentoCorrecto },
                    { nombre: 'aprobadoFormulaCorrecta', estado: item.aprobadoFormulaCorrecta },
                    { nombre: 'aprobadoAutorizacionCorrecta', estado: item.aprobadoAutorizacionCorrecta },
                    { nombre: 'aprobadoComprobantePago', estado: item.aprobadoComprobantePago },
                ];
                const aprobadosPresentes = aprobados.filter(aprobado => aprobado.estado !== undefined && aprobado.estado !== null);
                if (aprobadosPresentes.length === item.visibleCount) {
                    const todosAprobados = aprobadosPresentes.every(aprobado => aprobado.estado === true);
                    if (todosAprobados) {
                        item.todosAprobados = true;
                        // this.cambiarEstado(item);
                        this.$forceUpdate();
                    } else {
                        item.todosAprobados = false;
                        this.$forceUpdate();
                    }
                }
            }
            return false;
        },
        /**
         * Formatea la fecha en el formato 'DD-MM-YYYY h:mm a'.
         * 
         * @param {Date} fecha - La fecha a formatear.
         * @returns {string} La fecha formateada.
         */
        formatearFecha(fecha) {
            return moment(fecha).format('DD-MM-YYYY h:mm a');
        },
        /**
         * Traduce el estado de la solicitud al español.
         * 
         * @param {string} estado - El estado de la solicitud.
         * @returns {string} El estado traducido o el estado original si no se encuentra una traducción.
         */
        traducirEstado(estado) {
            const traducciones = {
                'CREATE': 'Creada',
                'VALIDATED': 'Validada',
                'INVOICED': 'Facturada',
                'DISPATCHED': 'Despachada',
                'RESOLVED': 'Resuelta',
                'REFUSED': 'Rechazada',
                'CLOSE': 'Cerrada',
                'OPEN': 'Abierta',
                'NOVELTY': 'Novedad',
            };

            return traducciones[estado] || estado;
        },
        /**
         * Obtiene la clase CSS correspondiente a un estado específico para resaltar su apariencia.
         */
        obtenerClaseEstado(estado) {
            const clases = {
                'CREATE': 'blue--text font-weight-bold',
                'VALIDATED': 'green--text font-weight-bold',
                'INVOICED': 'cyan--text font-weight-bold',
                'DISPATCHED': 'cyan--text font-weight-bold',
                'RESOLVED': 'cyan--text font-weight-bold',
                'REFUSED': 'red--text font-weight-bold',
                'CLOSE': 'green--text font-weight-bold',
                'OPEN': 'orange--text font-weight-bold',
                'NOVELTY': 'red--text font-weight-bold',
            };

            return clases[estado] || '';
        },
        /**
         * Aprueba el PDF visible en el modal actual, marcando el documento correspondiente como aprobado.
         * Ejecuta los métodos relacionados después de la aprobación.
         */
        aprobarPdf() {
            const procesarAprobacion = (aprobadoCampo, motivoCampo) => {
                this.$set(this.itemActual, aprobadoCampo, true);
                this.metodosEjecutados(this.itemActual);
                if (this.itemActual[motivoCampo]) {
                    this.itemActual[motivoCampo] = null;
                }
            };
            if (this.pdfVisible.documento === true) {
                procesarAprobacion('aprobadoDocumentoCorrecto', 'motivoDocumento');
                return;
            }
            if (this.pdfVisible.formula === true) {
                procesarAprobacion('aprobadoFormulaCorrecta', 'motivoFormula');
                return;
            }
            if (this.pdfVisible.autorizacion === true) {
                procesarAprobacion('aprobadoAutorizacionCorrecta', 'motivoAutorizacion');
                return;
            }
            if (this.pdfVisible.comprobantePago === true) {
                procesarAprobacion('aprobadoComprobantePago', 'motivoComprobantePago');
                return;
            }
        },
        /**
         * Rechaza el PDF visible en el modal actual, marcando el documento correspondiente como rechazado.
         * Ejecuta los métodos relacionados después de rechazar el documento.
         */
        rechazarPdf() {
            this.listaDeMotivoRechazo = [];
            const procesarRechazo = (motivosCampo, item) => {
                this.listaDeMotivoRechazo = this[motivosCampo];
                if (this.itemActual[item]) {
                    this.motivoRechazo = this.itemActual[item];
                } else {
                    this.motivoRechazo = null;
                }
                this.dialogoRechazar = true;
                this.motivoDeDocumento = false;
            };
            if (this.pdfVisible.documento === true) {
                procesarRechazo('motivosDocumento', 'motivoDocumento');
                return;
            }
            if (this.pdfVisible.formula === true) {
                procesarRechazo('motivosFormula', 'motivoFormula');
                return;
            }
            if (this.pdfVisible.autorizacion === true) {
                procesarRechazo('motivosAutorizacion', 'motivoAutorizacion');
                return;
            }
            if (this.pdfVisible.comprobantePago === true) {
                procesarRechazo('motivosPago', 'motivoComprobantePago');
                return;
            }
        },
        /**
         * Confirma el rechazo de un documento visible 
         * y establece sus variables para el flujo del proceso
         */
        confirmarRechazoPdf() {
            const procesarRechazo = (aprobadoCampo, motivoCampo) => {
                this.$set(this.itemActual, aprobadoCampo, false);
                this.metodosEjecutados(this.itemActual);
                this.$set(this.itemActual, motivoCampo, this.motivoRechazo);
                this.dialogoRechazar = false;
                setTimeout(() => {
                    this.motivoRechazo = null;
                    this.$refs.motivoRechazo?.reset();
                }, 300);
            };
            if (this.pdfVisible.documento === true) {
                procesarRechazo('aprobadoDocumentoCorrecto', 'motivoDocumento');
                return;
            }
            if (this.pdfVisible.formula === true) {
                procesarRechazo('aprobadoFormulaCorrecta', 'motivoFormula');
                return;
            }
            if (this.pdfVisible.autorizacion === true) {
                procesarRechazo('aprobadoAutorizacionCorrecta', 'motivoAutorizacion');
                return;
            }
            if (this.pdfVisible.comprobantePago === true) {
                procesarRechazo('aprobadoComprobantePago', 'motivoComprobantePago');
                return;
            }
        },
        /**
         * Muestra el documento de identidad asociado a la solicitud especificada.
         * Verifica si el registro del afiliado está validado y si se ha seleccionado una bodega antes de mostrar el documento.
         * Actualiza el estado del ítem y muestra el documento en un modal si la solicitud cumple con los requisitos.
         * @param {Object} item - La solicitud de la cual se desea ver el documento de identidad.
         */
        verDocumento(item) {
            this.nombreArchivoPdf = '';
            this.pdfEmbedActive = false;
            this.mostarDocumento = false;
            this.$set(item, 'alerta', null);
            if (item.registroAfiliado.estadoRegistro === false) {
                this.$set(item, 'alerta', true);
                this.$set(item, 'codigoBodega', null);
                this.mensajeAlerta = "Primero debe validar los derechos del paciente.";
                this.mensajeDeWarning = "Primero debe validar los derechos del paciente.";
                this.warningValidation = true;
                return;
            }
            if (item.codigoBodega === null || (item.bodegaSeleccionada !== undefined && item.bodegaSeleccionada === null)) {
                this.$set(item, 'sinBodega', true);
                this.mensajeSinBodega = "Primero seleccione un servicio.";
                this.mensajeDeWarning = "Primero seleccione un servicio.";
                this.warningValidation = true;
                this.limpiarVistaDePdf();
                return;
            }
            if (item.documentoCorrecto === false) {
                item.verDocumentoEjecutado = true;
            }
            this.cargaDeDocumento = true;
            this.pdfBase64 = null;
            this.modalTitulo = '';
            this.itemActual = item;
            this.$set(item, 'alerta', false);
            this.mensajeAlerta = "";
            this.$set(item, 'sinBodega', false);
            this.mensajeSinBodega = "";
            this.nombreArchivoPdf = `${item.idSolicitud}_${item.registroAfiliado.numeroDocumento}_${item.registroAfiliado.idRegistro}_documento_de_identificacion.pdf`;
            const rutaDocumento = `${item.registroAfiliado.idRegistro}/${item.registroAfiliado.documentoDigital}`;
            this.$http.get(`msa-external/api/solicitudEntrega/getFileBase64?fileName=${rutaDocumento}`)
            .then( response => {
                this.actualizarVistaDocumento(item);
                setTimeout(() => {
                    this.embedPDF(response.data);
                }, 500);
                this.pdfBase64 = `data:application/pdf;base64,${response.data}`;
            }).catch(error => {
                if ((item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN') && item.documentoCorrecto === false) {
                    this.actualizarVistaDocumento(item);
                } else {
                    this.mostrarBotones = false;
                    this.pdfBase64 = null;
                    this.modalTitulo = '';
                    this.cargaDeDocumento = false;
                    this.mostarDocumento = false;
                    this.nombreArchivoPdf = '';
                }
                this.manejarError(error);
            })
        },
        /**
         * Actualiza las variables para ver el archivo seleccionado de la solicitud a consultar
         */
        actualizarVistaDocumento(item) {
            this.modalTitulo = `Documento de identidad`;
            this.cargaDeDocumento = false;
            this.mostarDocumento = true;
            this.pdfVisible.documento = true;
            this.pdfVisible.formula = false;
            this.pdfVisible.autorizacion = false;
            this.pdfVisible.comprobantePago = false;
            if ((item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN') && item.documentoCorrecto === false) {
                this.mostrarBotones = true;
            } else {
                this.mostrarBotones = false;
            }
        },
        /**
         * Muestra la fórmula médica asociada a la solicitud especificada.
         * Verifica si el registro del afiliado está validado y si se ha seleccionado una bodega antes de mostrar la fórmula.
         * Actualiza el estado del ítem y muestra la fórmula en un modal si la solicitud cumple con los requisitos.
         * @param {Object} item - La solicitud de la cual se desea ver la fórmula médica.
         */
        verFormula(item) {
            this.nombreArchivoPdf = '';
            this.pdfEmbedActive = false;
            this.mostarDocumento = false;
            if (item.registroAfiliado.estadoRegistro === false) {
                this.$set(item, 'alerta', true);
                this.mensajeAlerta = "Primero debe validar los derechos del paciente.";
                this.mensajeDeWarning = "Primero debe validar los derechos del paciente.";
                this.warningValidation = true;
                return;
            }
            if (item.codigoBodega === null || (item.bodegaSeleccionada !== undefined && item.bodegaSeleccionada === null)) {
                this.$set(item, 'sinBodega', true);
                this.$set(item, 'codigoBodega', null);
                this.mensajeSinBodega = "Primero seleccione un servicio.";
                this.mensajeDeWarning = "Primero seleccione un servicio.";
                this.warningValidation = true;
                this.limpiarVistaDePdf();
                return;
            }
            if (item.formulaCorrecta === false) {
                item.verFormulaEjecutado = true;
            }
            this.cargaDeDocumento = true;
            this.pdfBase64 = null;
            this.modalTitulo = '';
            this.itemActual = item;
            this.$set(item, 'alerta', false);
            this.mensajeAlerta = "";
            this.$set(item, 'sinBodega', false);
            this.mensajeSinBodega = "";
            this.nombreArchivoPdf = `${item.idSolicitud}_${item.registroAfiliado.numeroDocumento}_${item.registroAfiliado.idRegistro}_formula.pdf`;
            const rutaDocumento = `${item.registroAfiliado.idRegistro}/${item.idSolicitud}/${item.nombreFormula}`;
            this.$http.get(`msa-external/api/solicitudEntrega/getFileBase64?fileName=${rutaDocumento}`)
            .then( response => {
                this.actualizarVistaFormula(item);
                setTimeout(() => {
                    this.embedPDF(response.data);
                }, 500);
                this.pdfBase64 = `data:application/pdf;base64,${response.data}`;
            }).catch(error => {
                if ((item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN') && item.formulaCorrecta === false) {
                    this.actualizarVistaFormula(item);
                } else {
                    this.mostrarBotones = false;
                    this.pdfBase64 = null;
                    this.modalTitulo = '';
                    this.cargaDeDocumento = false;
                    this.mostarDocumento = false;
                    this.nombreArchivoPdf = '';
                }
                this.manejarError(error);
            })
        },
        /**
         * Actualiza las variables para ver el archivo seleccionado de la solicitud a consultar
         */
        actualizarVistaFormula(item) {
            this.modalTitulo = `Fórmula médica`;
            this.cargaDeDocumento = false;
            this.mostarDocumento = true;
            this.pdfVisible.documento = false;
            this.pdfVisible.formula = true;
            this.pdfVisible.autorizacion = false;
            this.pdfVisible.comprobantePago = false;
            if ((item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN') && item.formulaCorrecta === false) {
                this.mostrarBotones = true;
            } else {
                this.mostrarBotones = false;
            }
        },
        /**
         * Muestra la autorización médica asociada a la solicitud especificada.
         * Verifica si el registro del afiliado está validado y si se ha seleccionado una bodega antes de mostrar la autorización.
         * Actualiza el estado del ítem y muestra la autorización en un modal si la solicitud cumple con los requisitos.
         * @param {Object} item - La solicitud de la cual se desea ver la autorización médica.
         */
        verAutorizacion(item) {
            this.nombreArchivoPdf = '';
            this.pdfEmbedActive = false;
            this.mostarDocumento = false;
            if (item.registroAfiliado.estadoRegistro === false) {
                this.$set(item, 'alerta', true);
                this.mensajeAlerta = "Primero debe validar los derechos del paciente.";
                this.mensajeDeWarning = "Primero debe validar los derechos del paciente.";
                this.warningValidation = true;
                return;
            }
            if (item.codigoBodega === null || (item.bodegaSeleccionada !== undefined && item.bodegaSeleccionada === null)) {
                this.$set(item, 'sinBodega', true);
                this.$set(item, 'codigoBodega', null);
                this.mensajeSinBodega = "Primero seleccione un servicio.";
                this.mensajeDeWarning = "Primero seleccione un servicio.";
                this.warningValidation = true;
                this.limpiarVistaDePdf();
                return;
            }
            if (item.autorizacionCorrecta === false) {
                item.verAutorizacionEjecutado = true;
            }
            this.cargaDeDocumento = true;
            this.pdfBase64 = null;
            this.modalTitulo = '';
            this.itemActual = item;
            this.$set(item, 'alerta', false);
            this.mensajeAlerta = "";
            this.$set(item, 'sinBodega', false);
            this.mensajeSinBodega = "";
            this.nombreArchivoPdf = `${item.idSolicitud}_${item.registroAfiliado.numeroDocumento}_${item.registroAfiliado.idRegistro}_autorizacion.pdf`;
            const rutaDocumento =  `${item.registroAfiliado.idRegistro}/${item.idSolicitud}/${item.nombreAutorizacion}`;
            this.$http.get(`msa-external/api/solicitudEntrega/getFileBase64?fileName=${rutaDocumento}`)
            .then( response => {
                this.actualizarVistaAutorizacion(item);
                setTimeout(() => {
                    this.embedPDF(response.data);
                }, 500);
                this.pdfBase64 = `data:application/pdf;base64,${response.data}`;
            }).catch(error => {
                if ((item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN') && item.autorizacionCorrecta === false) {
                    this.actualizarVistaAutorizacion(item);
                } else {
                    this.mostrarBotones = false;
                    this.pdfBase64 = null;
                    this.modalTitulo = '';
                    this.cargaDeDocumento = false;
                    this.mostarDocumento = false;
                    this.nombreArchivoPdf = '';
                }
                this.manejarError(error);
            })
        },
        /**
         * Actualiza las variables para ver el archivo seleccionado de la solicitud a consultar
         */
        actualizarVistaAutorizacion(item) {
            this.modalTitulo = `Autorización médica`;
            this.cargaDeDocumento = false;
            this.mostarDocumento = true;
            this.pdfVisible.documento = false;
            this.pdfVisible.formula = false;
            this.pdfVisible.autorizacion = true;
            this.pdfVisible.comprobantePago = false;
            if ((item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN') && item.autorizacionCorrecta === false) {
                this.mostrarBotones = true;
            } else {
                this.mostrarBotones = false;
            }
        },
        /**
         * Muestra la autorización para el reclamo de medicamentos por parte de un tercero que esta asociada a la solicitud especificada.
         * @param {Object} item - La solicitud seleccionada.
         */
        verAutorizacionTerceros(item) {
            this.nombreArchivoPdf = '';
            this.pdfEmbedActive = false;
            this.mostarDocumento = false;
            if (item.registroAfiliado.estadoRegistro === false) {
                this.$set(item, 'alerta', true);
                this.mensajeAlerta = "Primero debe validar los derechos del paciente.";
                this.mensajeDeWarning = "Primero debe validar los derechos del paciente.";
                this.warningValidation = true;
                return;
            }
            if (item.codigoBodega === null || (item.bodegaSeleccionada !== undefined && item.bodegaSeleccionada === null)) {
                this.$set(item, 'sinBodega', true);
                this.$set(item, 'codigoBodega', null);
                this.mensajeSinBodega = "Primero seleccione un servicio.";
                this.mensajeDeWarning = "Primero seleccione un servicio.";
                this.warningValidation = true;
                this.limpiarVistaDePdf();
                return;
            }
            this.cargaDeDocumento = true;
            this.pdfBase64 = null;
            this.modalTitulo = '';
            this.itemActual = item;
            this.$set(item, 'alerta', false);
            this.mensajeAlerta = "";
            this.$set(item, 'sinBodega', false);
            this.mensajeSinBodega = "";
            this.nombreArchivoPdf = `${item.idSolicitud}_${item.registroAfiliado.numeroDocumento}_${item.registroAfiliado.idRegistro}_autorizacion_tercero.pdf`;
            const rutaDocumento = `${item.registroAfiliado.idRegistro}/${item.idSolicitud}/${item.autorizacion}`;
            this.$http.get(`msa-external/api/solicitudEntrega/getFileBase64?fileName=${rutaDocumento}`)
            .then( response => {
                this.modalTitulo = `Autorización entrega de medicamentos a tercero`;
                this.cargaDeDocumento = false;
                this.mostarDocumento = true;
                this.pdfVisible.documento = false;
                this.pdfVisible.formula = false;
                this.pdfVisible.autorizacion = false;
                this.pdfVisible.comprobantePago = false;
                this.mostrarBotones = false;
                setTimeout(() => {
                    this.embedPDF(response.data);
                }, 500);
                this.pdfBase64 = `data:application/pdf;base64,${response.data}`;
            }).catch(error => {
                this.mostrarBotones = false;
                this.pdfBase64 = null;
                this.modalTitulo = '';
                this.cargaDeDocumento = false;
                this.mostarDocumento = false;
                this.nombreArchivoPdf = '';
                this.manejarError(error);
            })
        },
        /**
         * Muestra el comprobante de pago asociado a la solicitud especificada.
         * Verifica si el registro del afiliado está validado y si se ha seleccionado una bodega antes de mostrar el comprobante de pago.
         * Actualiza el estado del ítem y muestra el comprobante de pago en un modal si la solicitud cumple con los requisitos.
         * @param {Object} item - La solicitud de la cual se desea ver el comprobante de pago.
         */
        verComprobantePago(item) {
            this.nombreArchivoPdf = '';
            this.pdfEmbedActive = false;
            this.mostarDocumento = false;
            if (item.registroAfiliado.estadoRegistro === false) {
                this.$set(item, 'alerta', true);
                this.mensajeAlerta = "Primero debe validar los derechos del paciente.";
                this.mensajeDeWarning = "Primero debe validar los derechos del paciente.";
                this.warningValidation = true;
                return;
            }
            if (item.codigoBodega === null || (item.bodegaSeleccionada !== undefined && item.bodegaSeleccionada === null)) {
                this.$set(item, 'sinBodega', true);
                this.$set(item, 'codigoBodega', null);
                this.mensajeSinBodega = "Primero seleccione un servicio.";
                this.mensajeDeWarning = "Primero seleccione un servicio.";
                this.warningValidation = true;
                this.limpiarVistaDePdf();
                return;
            }
            if (item.comprobantePago === false) {
                item.verComprobantePagoEjecutado = true;
            }
            this.cargaDeDocumento = true;
            this.pdfBase64 = null;
            this.modalTitulo = '';
            this.itemActual = item;
            this.$set(item, 'alerta', false);
            this.mensajeAlerta = "";
            this.$set(item, 'sinBodega', false);
            this.mensajeSinBodega = "";
            this.nombreArchivoPdf = `${item.idSolicitud}_${item.registroAfiliado.numeroDocumento}_${item.registroAfiliado.idRegistro}_comprobante_de_pago.pdf`;
            const rutaDocumento = `${item.registroAfiliado.idRegistro}/${item.idSolicitud}/${item.nombreComprobante}`;
            this.$http.get(`msa-external/api/solicitudEntrega/getFileBase64?fileName=${rutaDocumento}`)
            .then( response => {
                this.actualizarVistaComprobantePago(item);
                setTimeout(() => {
                    this.embedPDF(response.data);
                }, 500);
                this.pdfBase64 = `data:application/pdf;base64,${response.data}`;
            }).catch(error => {
                if ((item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN') && item.comprobantePago === false) {
                    this.actualizarVistaComprobantePago(item);
                } else {
                    this.mostrarBotones = false;
                    this.pdfBase64 = null;
                    this.modalTitulo = '';
                    this.cargaDeDocumento = false;
                    this.mostarDocumento = false;
                    this.nombreArchivoPdf = '';
                }
                this.manejarError(error);
            })
        },
        /**
         * Actualiza las variables para ver el archivo seleccionado de la solicitud a consultar
         */
        actualizarVistaComprobantePago(item) {
            this.modalTitulo = `Comprobante de pago`;
            this.cargaDeDocumento = false;
            this.mostarDocumento = true;
            this.pdfVisible.documento = false;
            this.pdfVisible.formula = false;
            this.pdfVisible.autorizacion = false;
            this.pdfVisible.comprobantePago = true;
            if ((item.estadoSolicitudEntrega === 'CREATE' || item.estadoSolicitudEntrega === 'OPEN') && item.comprobantePago === false) {
                this.mostrarBotones = true;
            } else {
                this.mostrarBotones = false;
            }
        },
        /** 
         * Limpia las variables para mostrar el archivo cuando no hay una bodega selecionada 
         * y se intenta abrir un pdf de la solicitud. 
         */
        limpiarVistaDePdf() {
            this.modalTitulo = '';
            this.cargaDeDocumento = false;
            this.mostarDocumento = false;
            this.nombreArchivoPdf = '';
            this.pdfBase64 = '';
            this.pdfVisible.documento = false;
            this.pdfVisible.formula = false;
            this.pdfVisible.autorizacion = false;
            this.pdfVisible.comprobantePago = false;
            this.mostrarBotones = false;
        },
        /**
         * Valida que solo se ingresen caracteres alfanuméricos (letras y números) en un campo de texto.
         *
         * @param event El evento de teclado que activa esta función.
         * @description Si se presiona una tecla que no sea una letra o número, evita que se registre en el campo.
         */
        validarLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9A-Za-z-]/)) {
                event.preventDefault();
            }
        },
        /**
         * Valida que solo se ingresen letras (alfabeto) y espacios en un campo de texto.
         *
         * @param event El evento de teclado que activa esta función.
         * @description Si se presiona una tecla que no sea una letra o espacio, evita que se registre en el campo.
         */
        validarSoloLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9]/)) {
                event.preventDefault();
            }
        },
        /**
         * Navega a la primera página del historial de solicitudes de entrega y actualiza la lista de solicitudes.
         */
        irPrimeraPagina() {
            this.pagina = 1;
            this.listarSolicitudes();
        },
        /**
         * Navega a la página anterior del historial de solicitudes de entrega, si es posible, y actualiza la lista de solicitudes.
         */
        anteriorPagina() {
            if (this.pagina > 1) {
                this.pagina--;
                this.listarSolicitudes();
            }
        },
        /**
         * Navega a la siguiente página del historial de solicitudes de entrega, si es posible, y actualiza la lista de solicitudes.
         */
        siguientePagina() {
            if (this.pagina * this.itemsPorPagina < this.totalElements) {
                this.pagina++;
                this.listarSolicitudes();
            }
        },
        /**
         * Navega a la última página del historial de solicitudes de entrega y actualiza la lista de solicitudes.
         */
        irUltimaPagina() {
            this.pagina = Math.ceil(this.totalElements / this.itemsPorPagina);
            this.listarSolicitudes();
        },
        /**
         * Cambia la cantidad de ítems por página y actualiza la lista de solicitudes.
         */
        cambiarItemsPorPagina() {
            this.listarSolicitudes();
        },
        /**
         * Lista las solicitudes de entrega con filtros opcionales y manejo de paginación.
         * Limpia los PDF visibles antes de realizar la operación.
         */
        async listarSolicitudes() {
            if (this.dialogoFiltros === true) {
                this.dialogoFiltros = false;
            }
            this.limpiarPdfVisible();
            let idSoli = this.buscadorIdsolicitud === null ? '' : this.buscadorIdsolicitud;
            let numDocu = this.buscadorDocumento === null ? '' : this.buscadorDocumento;
            let tipSoli = this.buscarTipoSolicitud !== null ? this.buscarTipoSolicitud : '';
            let estado = this.buscarEstado === null ? '' : this.buscarEstado;
            let aseguradora = this.buscadorAseguradora === null ? '' : this.buscadorAseguradora;
            let bodega = this.buscadorServicio !== null ? this.buscadorServicio : '';
            if (idSoli !== '' || bodega !== '') {
                this.filtrosInternos = true;
            }
            const pagina = this.pagina - 1;
            
            try {
                const response = await this.$http.get(`msa-external/api/solicitudEntrega/listarSolicitudes`, {
                    params: {
                        page: pagina,
                        size: this.itemsPorPagina,
                        idSolicitud: idSoli,
                        numeroDocumento: `${numDocu}`,
                        tipoSolicitud: `${tipSoli}`,
                        estado: `${estado}`,
                        idAseguradora: `${aseguradora}`,
                        codigoBodega: bodega,
                        idEmpresa: this.enterprise.code,
                    },
                });
                if (response.data.body.content && response.data.body.content.length > 0) {
                    this.listaDeSolicitudes = response.data.body.content;
                    this.listaDeSolicitudes.forEach(item => {
                        this.todosLosTdVisibles(item);
                    });
                    this.totalElements = response.data.body.totalElements;
                    this.mostrarSolicitudes = true;
                    
                    await Promise.all(this.listaDeSolicitudes.map(async item => {
                        await this.bodegasSolicitud(item);
                        await this.motivosRechazo(item);
                    }));
                } else {
                    this.mostrarSolicitudes = false;
                }

                this.cantidadSolicitudesPorEstado();
                
            } catch (error) {
                this.manejarError(error);
            }
        },
        /**
         * Obtiene información sobre las bodegas asociadas a una solicitud de entrega.
         * Actualiza los datos de la solicitud con la información de las bodegas consultadas.
         */
        async bodegasSolicitud(item) {
            if (item.bodegas.bodegasId.codigoBodega === 0 && item.tipoSolicitud === "SCHEDULED_DELIVERY") {
                try {
                    const response = await this.$http.get(`msa-external/api/servicios/1/${item.ciudad.idCiudad}`)
                    const bodegas = response.data;
                    if (bodegas.length === 1) {
                        this.$set(item, 'bodegaConsultada', bodegas[0].nombreBodega);
                        this.$set(item, 'codigoBodega', bodegas[0].codigoBodega);
                    } else if(bodegas.length > 1 || bodegas.length === 0){
                        const response = await this.$http.get(`msa-external/api/servicios/1`)
                        const bodegas = response.data;
                        if (bodegas.length > 1) {
                            this.$set(item, 'bodegaConsultada', null);
                            this.$set(item, 'codigoBodega', null);
                            this.$set(item, 'listaDeBodegasSolicitud', bodegas); 
                        }
                    }
                } catch (error) {
                    this.manejarError(error);
                }
                
            } else {
                this.$set(item, 'bodegaConsultada', item.bodegas.nombreBodega);
                this.$set(item, 'codigoBodega', item.bodegas.bodegasId.codigoBodega);
            }
        },
        /**
         * Consulta todos los servicios en la empresa 1, para cambiar la bodega de la solicitud.
         * @param item - objecto del que se toma la bodega actual que esta selecionada.
         */
        async cambiarBodegaSolicitud(item) {
            try {
                const response = await this.$http.get(`msa-external/api/servicios/1`)
                const bodegas = response.data;
                if (bodegas.length > 1) {
                    this.$set(item, 'bodegaSeleccionada', item.codigoBodega);
                    this.$set(item, 'bodegaConsultada', null);
                    this.$set(item, 'codigoBodega', null);
                    this.$set(item, 'listaDeBodegasSolicitud', bodegas); 
                }
            } catch (error) {
                this.manejarError(error);
            }
        },
        /**
         * Consulta los motivos de rechazo de las solicitudes en estado novedad y en estado rechazadas.
         * Almacena los motivos en variables internas dentro de cada solicitud para permitir su visualización.
         * @param item - La solicitud seleccionada.
         */
        async motivosRechazo(item) {
            let motivos = [];
            if (item.estadoSolicitudEntrega === 'NOVELTY') {
                try {
                    const response = await this.$http.get(`msa-external/api/motivo-solicitud/${item.idSolicitud}/${'R'}`);
                    if (response.data && response.data !== '') {
                        response.data.forEach(motivo => {
                            motivos.push({
                                descripcion: motivo.descripcionMotivo,
                                observacion: motivo.observacion
                            });
                        });
                    }
                } catch (error) {
                    this.manejarError(error);
                }
            } else if (item.estadoSolicitudEntrega === 'REFUSED' || item.estadoSolicitudEntrega === 'CLOSE') {
                try {
                    const response = await this.$http.get(`msa-external/api/motivo-solicitud/${item.idSolicitud}/${'I'}`);
                    if (response.data && response.data !== '') {
                        response.data.forEach(motivo => {
                            motivos.push({
                                descripcion: motivo.descripcionMotivo,
                                observacion: motivo.observacion
                            });
                        });
                    }
                } catch (error) {
                    this.manejarError(error);
                }
            }
            this.$set(item, 'motivos', motivos);
        },
        /**
         * Abre el dialogo para ver los detalles del motivo
         * 
         * @param item - Objecto que repesenta la solicitud selecionada 
         */
        verDetalleDelMotivo(item) {
            this.mensajeDelDetalleDelMotivo = item.motivos || [];
            this.dialogoDetalleDelMotivo = true;
        },
        /**
         * Cierra el dialogo para ver el detalle del motivo y restablece sus variables.
         */
        cerrarDialogoDetalleDelMotivo(){
            this.dialogoDetalleDelMotivo = false;
            setTimeout(() => {
                this.mensajeDelDetalleDelMotivo = [];
            }, 500);
        },
        /**
         * Valida el tipo de dirección "Urbana o Rural".
         * De acuerdo al tipo decide como mostrar la direción.
         * @param {*} item La solicitud a domicilio a validar.
         */
        consultarTipoDirecion(item) {
            const esDireccionUrbana = this.tiposVias.some(opcion => item.direccionEntrega.startsWith(opcion));
            if (esDireccionUrbana) {
                return `${item.direccionEntrega}, ${item.barrio !== null ? item.barrio.barrio + ', ' : ''} ${item.ciudad.ciudad}, ${item.departamento.departamento}`
            } else {
                return `${item.direccionEntrega}, ${item.ciudad.ciudad}, ${item.departamento.departamento}`
            } 
        },
        /**
         * Actualiza la bodega consultada en una solicitud de entrega con la bodega seleccionada por el usuario.
         */
        updateBodegaConsultada(item) {
            if (item.bodegaSeleccionada) {
                const selectedBodega = item.listaDeBodegasSolicitud.find(bodega => bodega.codigoBodega === item.bodegaSeleccionada);
                if (selectedBodega) {
                    this.$set(item, 'bodegaConsultada', selectedBodega.nombreBodega);
                    this.$set(item, 'codigoBodega', selectedBodega.codigoBodega);
                }
            }
        },
        /**
         * Abre el diálogo para rechazar una solicitud específica.
         * Establece la solicitud seleccionada para ser rechazada.
         * @param {Object} item - La solicitud que se desea rechazar.
         */
        abrirDialogoRechazar(item) {
            if (item.codigoBodega === null || (item.bodegaSeleccionada !== undefined && item.bodegaSeleccionada === null)) {
                this.$set(item, 'sinBodega', true);
                this.$set(item, 'codigoBodega', null);
                this.mensajeSinBodega = "Primero seleccione un servicio.";
                this.mensajeDeWarning = "Primero seleccione un servicio.";
                this.warningValidation = true;
                this.limpiarVistaDePdf();
                return;
            }
            this.$set(item, 'motivoGeneral', true);
            if (item.nombreAutorizacion !== null && item.nombreAutorizacion !== '') {
                this.listaDeMotivoRechazo = this.motivosGenerales.filter(motivo => 
                    motivo.text !== "Sin autorización médica" || motivo.value !== 18
                );
            } else {
                this.listaDeMotivoRechazo = this.motivosGenerales;
            }
            let motivoDocumento = item.motivoDocumento && item.motivoDocumento !== '' ? true : false;
            let motivoFormula = item.motivoFormula && item.motivoFormula !== '' ? true : false;
            let motivoAutorizacion = item.motivoAutorizacion && item.motivoAutorizacion !== '' ? true : false;
            let motivoComprobantePago = item.motivoComprobantePago && item.motivoComprobantePago !== '' ? true : false;
            if (motivoDocumento || motivoFormula || motivoAutorizacion || motivoComprobantePago) {
                this.motivoDeDocumento = true;
            } else {
                this.motivoDeDocumento = false;
            }
            this.dialogoRechazar = true;
            this.solicitudSeleccionada = item;
        },
        /**
         * Rechaza la solicitud seleccionada, especificando el motivo y actualizando su estado en el sistema.
         * @fires listarSolicitudes - Actualiza la lista de solicitudes después de rechazar una.
         * @fires limpiarPdfVisible - Limpia las variables relacionadas con la visualización del PDF.
         */
        rechazar() {
            if ((this.solicitudSeleccionada.motivoGeneral && this.solicitudSeleccionada.motivoGeneral === true) && this.itemActual.codigoBodega !== null) {
                this.inProgress = true;
                if(this.motivoRechazo !== null && this.motivoRechazo !== '') { 
                    this.$set(this.solicitudSeleccionada, 'motivoGeneral', this.motivoRechazo) 
                }
                let tipoGeneral = "";
                let tipo = this.motivoRechazoAll.find(m => m.value === this.motivoRechazo);
                if (typeof tipo === 'object') {
                    tipoGeneral = tipo.tipo;
                }
                const listaMotivos = {
                    motivoDocumento: this.solicitudSeleccionada.motivoDocumento || null,
                    motivoFormula: this.solicitudSeleccionada.motivoFormula || null,
                    motivoAutorizacion: this.solicitudSeleccionada.motivoAutorizacion || null,
                    motivoComprobantePago: this.solicitudSeleccionada.motivoComprobantePago || null,
                    motivoGeneral: this.solicitudSeleccionada.motivoGeneral || null
                };
                const esMotivoGeneralBooleano = typeof this.solicitudSeleccionada.motivoGeneral === 'boolean';
                let ultimoMotivoValido = null;
                const motivos = Object.entries(listaMotivos).map(([key, motivo]) => {
                    let adjunto = "";
                    let observacion = "";
                    let tipoMotivo = "";
                    if (key === "motivoGeneral" && motivo !== null) {  
                        if (esMotivoGeneralBooleano) {
                            return null;
                        } else {
                            adjunto = this.documento.adjuntoRechazo ? this.documento.adjuntoRechazo.replace(/^data:application\/pdf;base64,/, '') : "";
                            observacion = this.observacionRechazo === null ? "" : this.observacionRechazo;
                            tipoMotivo = tipoGeneral;
                        }
                    } else {
                        tipoMotivo = "R";
                    }
                    if (motivo !== null) {
                        ultimoMotivoValido = { idMotivo: motivo, adjunto, observacion, tipoMotivo };
                    }
                    return motivo !== null ? { idMotivo: motivo, adjunto, observacion, tipoMotivo } : null;
                }).filter(motivo => motivo !== null);
                if (esMotivoGeneralBooleano && ultimoMotivoValido) {
                    ultimoMotivoValido.adjunto = this.documento.adjuntoRechazo ? this.documento.adjuntoRechazo.replace(/^data:application\/pdf;base64,/, '') : "";
                    ultimoMotivoValido.observacion = this.observacionRechazo === null ? "" : this.observacionRechazo;
                }
                const motivosFinales = motivos.map(motivo => {
                    if (motivo.idMotivo === ultimoMotivoValido.idMotivo) {
                        return ultimoMotivoValido;
                    }
                    return motivo;
                });
                const rechazar = {
                    idSolicitud: this.solicitudSeleccionada.idSolicitud,
                    autorizacionCorrecta: this.solicitudSeleccionada.aprobadoAutorizacionCorrecta != undefined ? this.solicitudSeleccionada.aprobadoAutorizacionCorrecta : this.solicitudSeleccionada.autorizacionCorrecta,
                    comprobantePago: this.solicitudSeleccionada.aprobadoComprobantePago != undefined ? this.solicitudSeleccionada.aprobadoComprobantePago : this.solicitudSeleccionada.comprobantePago,
                    documentoCorrecto: this.solicitudSeleccionada.aprobadoDocumentoCorrecto != undefined ? this.solicitudSeleccionada.aprobadoDocumentoCorrecto : this.solicitudSeleccionada.documentoCorrecto,
                    formulaCorrecta: this.solicitudSeleccionada.aprobadoFormulaCorrecta != undefined ? this.solicitudSeleccionada.aprobadoFormulaCorrecta : this.solicitudSeleccionada.formulaCorrecta,
                    motivos: motivosFinales,
                    bodegas: {
                        bodegasId: {
                            idEmpresa: this.solicitudSeleccionada.empresa.idEmpresa,
                            codigoBodega: this.solicitudSeleccionada.codigoBodega,
                        }
                    },
                }
                this.$http
                    .post(`msa-external/api/solicitudEntrega/editar`, rechazar)
                    .then(() => {
                        this.pagina = 1;
                        this.listarSolicitudes();
                        this.solicitudSeleccionada = {};
                        this.limpiarPdfVisible();
                        this.cerrarDialogoRechazar();
                        this.inProgress = false;
                    }).catch(error => {
                        this.$set(this.solicitudSeleccionada, 'motivoGeneral', true);
                        this.manejarError(error);
                        this.inProgress = false;
                    });
            } 
        },
        /**
         * Cierra el dialogo donde se ingresa el motivo del rechazo y limpia el campo.
         */
        cerrarDialogoRechazar() {
            this.dialogoRechazar = false;
            this.motivoDeDocumento = false;
            if (this.solicitudSeleccionada && this.solicitudSeleccionada.motivoGeneral) {
                setTimeout(() => {
                    this.$set(this.solicitudSeleccionada, 'motivoGeneral', false);
                    this.motivoRechazo = null;
                    this.observacionRechazo = null;
                    this.$refs.motivoRechazo?.reset();
                    this.documento.adjuntoRechazo = null;
                    this.formatoIncorrecto.adjuntoRechazo = null;
                    this.$refs.adjuntoRechazoFile?.reset();
                }, 300)
            } else {
                setTimeout(() => {
                    this.motivoRechazo = null;
                    this.observacionRechazo = null;
                    this.$refs.motivoRechazo?.reset();
                }, 300)
            }
        },
        /**
         * Limpia las variables relacionadas con la visualización del PDF y los botones de aprobación/rechazo.
         * Se utiliza después de procesar una solicitud.
         */
        limpiarPdfVisible() {
            this.pdfBase64 = null;
            this.modalTitulo = '';
            this.cargaDeDocumento = false;
            this.mostarDocumento = false;
            this.pdfEmbedActive = false;
            this.pdfVisible.documento = false;
            this.pdfVisible.formula = false;
            this.pdfVisible.autorizacion = false;
            this.pdfVisible.comprobantePago = false;
            this.mostrarBotones = false;
            this.motivoRechazo = null;
            this.motivoDeDocumento = false;
        },
        /**
         * Cambia el estado de la solicitud seleccionada y actualiza su estado en el sistema.
         * @param {Object} item - La solicitud para la cual se cambia el estado.
         * @fires listarSolicitudes - Actualiza la lista de solicitudes después de cambiar el estado.
         * @fires limpiarPdfVisible - Limpia las variables relacionadas con la visualización del PDF.
         */
        cambiarEstado(item) {
            if (item.codigoBodega === null || (item.bodegaSeleccionada !== undefined && item.bodegaSeleccionada === null)) {
                this.$set(item, 'sinBodega', true);
                this.$set(item, 'codigoBodega', null);
                this.mensajeSinBodega = "Primero seleccione un servicio.";
                this.mensajeDeWarning = "Primero seleccione un servicio.";
                this.warningValidation = true;
                this.limpiarVistaDePdf();
                return;
            }
            this.inProgress = true;
            this.validateProgress = true;
            const validar = {
                idSolicitud: item.idSolicitud,
                autorizacionCorrecta: item.aprobadoAutorizacionCorrecta !== undefined ? item.aprobadoAutorizacionCorrecta : item.autorizacionCorrecta,
                comprobantePago: item.aprobadoComprobantePago !== undefined ? item.aprobadoComprobantePago : item.comprobantePago,
                documentoCorrecto: item.aprobadoDocumentoCorrecto !== undefined ? item.aprobadoDocumentoCorrecto : item.documentoCorrecto,
                formulaCorrecta: item.aprobadoFormulaCorrecta !== undefined ? item.aprobadoFormulaCorrecta : item.formulaCorrecta,
                motivos: [],
                bodegas: {
                    bodegasId: {
                        idEmpresa: item.empresa.idEmpresa,
                        codigoBodega: item.codigoBodega,
                    }
                },
            }
            this.$http
                .post(`msa-external/api/solicitudEntrega/editar`, validar)
                .then(() => {
                    this.pagina = 1;
                    this.listarSolicitudes();
                    this.solicitudSeleccionada = {};
                    this.limpiarPdfVisible();
                    this.inProgress = false;
                    this.validateProgress = false;
                }).catch(error => {
                    this.manejarError(error);
                    this.inProgress = false;
                    this.validateProgress = false;
                });
        },
        /**
         * Abre el diálogo para validar los datos del registro de un afiliado.
         * @param {Object} item - El ítem que contiene los datos del registro del afiliado.
         * Establece los datos del afiliado para validar.
         */
        abrirValidacionDeDatos(item) {
            this.limpiarPdfVisible();
            this.dialogoValidacionDeDatos = true;
            this.validarNombres = `${item.registroAfiliado.primerNombre} ${item.registroAfiliado.segundoNombre}`;
            this.validarApellidos = `${item.registroAfiliado.primerApellido} ${item.registroAfiliado.segundoApellido}`;
            this.validaridentificacion = `${item.registroAfiliado.tipoDocumento.descripcion}: ${item.registroAfiliado.numeroDocumento}`;
            this.validarAseguradora = item.aseguradora.nombreAseguradora;
            this.validarGenero = item.registroAfiliado.generoValue;
            this.validarRegimen = item.registroAfiliado.regimen;
            this.validarIdKeycloak = item.registroAfiliado.idKeycloak;
            this.validarIdRegistro = item.registroAfiliado.idRegistro;
            this.validarEstadoRegistro = item.registroAfiliado.estadoRegistro;
            this.validaridEmpresa = item.empresa.idEmpresa;
        },
        /**
         * Formatea el género del afiliado para mostrar su nombre completo.
         * @param {string} validarGenero - El género del afiliado a formatear.
         * @returns {string} - El género formateado.
         */
        formatoGenero(validarGenero) {
            const generoCompleto = {
                'M': 'Masculino',
                'F': 'Femenino',
                'N': 'Sin dato',
                'I': 'Indeterminado',
            };
            return generoCompleto[validarGenero] || validarGenero;
        },
        /**
         * Abre el dialogo para el rechazo del paciente, 
         * Establece la variable rechazoDePaciente en true que se toma para mostrarle solo algunos motivos de rechazo.
         */
        abrirValidacionDePaciente() {
            this.dialogoValidacionPaciente = true;
            this.rechazoDePaciente = true;
            this.listaDeMotivoRechazo = this.motivosDerechos;
        },
        /**
         * Rechaza la validación de los datos del registro del afiliado.
         * Envía la solicitud para anular el registro con el motivo de rechazo proporcionado.
         * @fires listarSolicitudes - Actualiza la lista de solicitudes después de rechazar la validación.
         */
        rechazarValidacionDeDatos() {
            this.inProgress = true;
            this.dialogoValidacionPaciente = false;
            const rechazar = {
                idRegistro: this.validarIdRegistro, 
                idkeycloak: this.validarIdKeycloak,
                idMore: this.motivoRechazo
            }
            this.$http.post('msa-external/api/anular-registro', rechazar)
            .then(() => {
                this.listarSolicitudes();
                this.limpiarFormularioValidacion();
                this.motivoRechazo = null;
                this.rechazoDePaciente = false;
                this.pagina = 1;
                this.inProgress = false;
            }).catch(error => {
                this.manejarError(error);
                this.inProgress = false;
            });
        },
        /**
         * Cierra el dialogo donde se ingresa el motivo del rechazo al validar los datos del paciente y limpia el campo.
         */
        cerrarDialogoRechazarPaciente() {
            this.dialogoValidacionPaciente = false;
            this.motivoRechazo = null;
            this.rechazoDePaciente = false;
            this.$refs.observerRechazarPaciente?.reset();
        },
        /**
         * Aprueba la validación de los datos del registro del afiliado.
         * Envía la solicitud para aprobar el registro del afiliado.
         * @fires listarSolicitudes - Actualiza la lista de solicitudes después de aprobar la validación.
         */
        aprobarValidacionDeDatos() {
            this.inProgress = true;
            const aprobar = {
                idEmpresa: this.validaridEmpresa, 
                idRegistro: this.validarIdRegistro,
                estadoRegistro: true
            }
            this.$http.post('msa-external/api/aprobar-registro', aprobar )
            .then(() => {
                this.listarSolicitudes();
                this.limpiarFormularioValidacion();
                this.pagina = 1;
                this.inProgress = false;
            }).catch(error => {
                this.manejarError(error);
                this.inProgress = false;
            });
        },
        /**
         * Limpia el formulario de validación de datos del paciente.
         */
        limpiarFormularioValidacion() {
            this.dialogoValidacionDeDatos = false;
            this.dialogoValidacionPaciente = false;
            this.validarNombres = '';
            this.validarApellidos = '';
            this.validaridentificacion = '';
            this.validarAseguradora = '';
            this.validarGenero = '';
            this.validarRegimen = '';
            this.validarIdKeycloak = '';
            this.validarIdRegistro = '';
            this.validarEstadoRegistro = '';
            this.validaridEmpresa = '';
        },
        /**
         * Establece el índice del trabajo seleccionado.
         * @param {number} index - El índice del trabajo seleccionado.
         */
        seleccionarTrabajo(index) {
            this.indiceTrabajando = index;
        },
        /**
         * Limpia la selección del trabajo actual.
         */
        limpiarTrabajo() {
            this.indiceTrabajando = null;
        },
        /**
         * Obtiene la lista de motivos de rechazo desde el servidor.
         * Actualiza la lista de motivos de rechazo disponibles en el componente.
         */
        listarMotivoRechazo() {
            this.listaDeMotivoRechazo = [];
            this.motivosDerechos = [];
            this.motivosDocumento = [];
            this.motivosFormula = [];
            this.motivosAutorizacion = [];
            this.motivosPago = [];
            this.motivosGenerales = [];
            this.motivosNovedad = [];
            this.motivoRechazoAll = [];
            let motivosListos = [];
            const categorias = {
                'A': this.motivosAutorizacion,
                'D': this.motivosDerechos,
                'F': this.motivosFormula,
                'I': this.motivosDocumento,
                'G': this.motivosGenerales,
                'P': this.motivosPago,
                'N': this.motivosNovedad,
                'L': motivosListos,
            };
            this.$http.get(`msa-external/api/motivo/list`)
            .then((response) => {
                const data = response.data;
                this.motivoRechazoAll = data.map(item => ({
                    text: `${item.descripcionMotivo}`,
                    value: item.idMore,
                    tipo: item.tipoMotivo,
                }));
                let filteredData = data;
                filteredData.forEach(item => {
                    const motivo = {
                        text: `${item.descripcionMotivo}`,
                        value: item.idMore,
                    };
                    const categoria = categorias[item.tipoProceso];
                    
                    categoria.push(motivo);
                });
            }).catch((error) => {
                this.manejarError(error);
            })
            
        },
        /**
         * Actualiza el array motivosSeleccionados con los id de los motivos de rechazo seleccionados 
         */
        updateSelectedMotivos() {
            this.motivosSeleccionados = this.listaDeMotivoRechazo
                .filter(motivo => motivo.selected)
                .map(motivo => motivo.value);
        },
        /**
         * Recupera una lista de bodegas desde el servidor y la almacena en la propiedad listaServicios.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        async listarServicios() {
            this.listaServicios = [];
            try {
                const response = await this.$http.get(`msa-external/api/bodegas/list/${this.enterprise.code}`);
                const filteredData = response.data.filter(item => item.bodegasId.codigoBodega !== 0);
                this.listaServicios = filteredData.map(item => ({
                    text: `${item.bodegasId.codigoBodega} - ${item.nombreBodega}`,
                    value: item.bodegasId.codigoBodega,
                }));
            } catch (error) {
                this.manejarError(error);
            }
        },
        /**
         * Recupera una lista de aseguradoras desde el servidor y la almacena en la propiedad listaAseguradoras.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        async listarAseguradoras() {
            this.listaAseguradoras = [];
            try {
                const response = await this.$http.get(`msa-external/api/aseguradora/list/${this.enterprise.code}`);
                this.listaAseguradoras = response.data.map(item => ({
                    text: `${item.idAseguradora} - ${item.nombreAseguradora}`,
                    value: item.idAseguradora,
                }));
            } catch (error) {
                this.manejarError(error);
            }
        },
        /**
        * Formatea un número ingresado en un campo específico.
        * @param {string} e - Valor ingresado en el campo.
        * @param {string} campo - Nombre del campo a formatear, que se usa en las lineas 143 y 162
        * para el campo valorPago.
        */
        numeroFormateado(e, campo) {
            campo = ''
            const tmpValor = e.replace(/[^0-9-]+/g, "")
            const formatter = new Intl.NumberFormat('es-CO', {
                currency: 'COP'
            });
            campo = '$ ' + formatter.format(tmpValor);
            this.valorPago = campo;
        },
        /**
         * Muestra una notificación global en el componente.
         *
         * @param {string} mensaje - Mensaje que se mostrará en la notificación.
         * @param {string} tipo - Tipo de la notificación (por defecto, "error").
         * @param {string} icono - Icono de la notificación (por defecto, "highlight_off").
         */
        mostrarNotificacionGlobal(mensaje, tipo, icono) {
            this.mostrarNotificacion = true;
            this.tipoNotificacion = tipo || "advertencia"; // Tipo predeterminado es "error"
            this.tipoNotificacionColor = this.obtenerColorNotificacion(this.tipoNotificacion);
            this.iconoNotificacion = icono || "highlight_off"; // Icono predeterminado es "highlight_off"
            this.mensajeNotificacion = mensaje;
            this.iconoNotificacionClase = this.obtenerClaseIcono(this.tipoNotificacion);

            // Cierra la notificación después de 5 segundos
            setTimeout(() => {
                this.cerrarNotificacion();
            }, 5000); 
        },
        /**
         * Cierra la notificación
         */
        cerrarNotificacion() {
            this.mostrarNotificacion = false;
            this.mensajeNotificacion = "";
            this.tipoNotificacion = "";
        },
        /**
         * Obtiene el color correspondiente al tipo de notificación.
         * 
         * @param {string} tipo - Tipo de notificación.
         * @returns {string} - Color de la notificación.
         */
        obtenerColorNotificacion(tipo) {
            switch (tipo) {
                case "advertencia":
                return "#f80505";
                default:
                return "#f80505"; 
            }
        },
        /**
         * Obtiene la clase de icono correspondiente al tipo de notificación.
         * 
         * @param {*} tipo  - Tipo de notificación.
         * @returns {string} - Clase de icono.
         */
        obtenerClaseIcono(tipo) {
            switch (tipo) {
                case "advertencia":
                return "advertencia-icon";
                default:
                return "advertencia-icon";
            }
        },
        /**
         * Maneja errores y muestra notificaciones correspondientes.
         * 
         * @param {*} error - Objeto de error.
         */
        manejarError(error) {
            let mensajeError = "Error inesperado, contacta con el administrador.";
            if (error.response) {
                if (error.response.data && error.response.data.message) {
                    mensajeError = error.response.data.message;
                } else if (error.response.data) {
                    mensajeError = JSON.stringify(error.response.data);
                }
            }
            
            const maxLength = 200;
            if (mensajeError.length > maxLength) {
                mensajeError = mensajeError.substring(0, maxLength) + "...";
            }
            
            this.mostrarNotificacionGlobal(mensajeError, "advertencia", "highlight_off" );
            // if (error.response) {
            //     if (error.response.status === 503) {
            //         this.mostrarNotificacionGlobal("Error en la red, inténtalo más tarde.", "advertencia", "highlight_off" );
            //     } else if (error.response.status === 401) {
            //     this.mostrarNotificacionGlobal("Error de autorización, contacta con el administrador.", "advertencia", "highlight_off" );
            //     } else {
            //         this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
            //     }
            // } else {
            //     this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
            // }
        },
        /** 
         * Define el ancho de las listas para los filtros
         */
        sizeList() {
            this.$nextTick(() => {
                if (this.$refs.filtroTipo) {
                    this.menuPropsFiltroTipo.maxWidth = this.$refs.filtroTipo.$el.offsetWidth;
                }
                if (this.$refs.filtroAseguradora) {
                    this.menuPropsFiltroAseguradora.maxWidth = this.$refs.filtroTipo.$el.offsetWidth;
                }
                if (this.$refs.filtroServicio) {
                    this.menuPropsFiltroServicio.maxWidth = this.$refs.filtroServicio.$el.offsetWidth;
                }
            });
        },
        /** 
         * Define el ancho del campo que permite selecionar un servico para la solicitud 
         */
        serviceFieldSize() {
            this.$nextTick(() => {
                if (this.$refs.servicioSolicitud) {
                    setTimeout(() => {
                        this.menuPropsServicio.maxWidth = this.$refs.servicioSolicitud[0].$el.offsetWidth;
                    }, 100)
                }
            });
        },
        /**
         * Obtiene la clase CSS correspondiente a un tipo de solicitud específico para resaltar su apariencia.
         * @param tipoSolicitud - tipo de solicitud obtenido.
         */
        colorTipoSolicitud(tipoSolicitud) {
            const color = {
                'SCHEDULED_DELIVERY' : 'primary--text font-weight-bold',
                'HOME_DELIVERY' : 'color-domicilio'
            };
            return color[tipoSolicitud] || '';
        },
        /**
         * Maneja el evento de presionar la tecla Tab para campos v-autocomplete.
         * Si se presiona la tecla Tab y el valor actual del modelo es null,
         * desenfoca el campo y establece el valor del modelo como una cadena vacía.
         *
         * @param {KeyboardEvent} event - El objeto del evento de teclado.
         * @param {string} model - El nombre de la propiedad del modelo.
         * @param {string} ref - El nombre de la referencia del campo v-autocomplete.
         */
        tabulador(event, model, ref) {
            if (event.key === 'Tab' && this[model] === null) {
                this.$refs[ref].blur();
                this[model] = '';
            }
        },
        /**
         * Limpia los filtros internos y lista nuevamente las solicitudes.
         */
        cleanFiltrosInternos() {
            this.dialogoFiltros = false;
            if ((this.buscadorIdsolicitud !== null || this.buscadorServicio !== null) && this.filtrosInternos === true) {
                this.filtrosInternos = false;
                setTimeout(() => {
                    this.buscadorIdsolicitud = null;
                    this.buscadorServicio = null;
                    this.listarSolicitudes();
                }, 300)
            } else {
                setTimeout(() => {
                    this.buscadorIdsolicitud = null;
                    this.buscadorServicio = null;
                }, 300)
            }
        },
        /**
         * Obtiene una cantidad de solicitudes por cada tipo de estado.
         */
        async cantidadSolicitudesPorEstado() {
            try {
                const response = await this.$http.get(`msa-external/api/solicitudEntrega/cantidades`);
                this.abiertas = response.data.abierta;
                this.creadas = response.data.creada;
                this.validadas = response.data.validada;
                this.rechazadas = response.data.rechazada;
                this.resueltas = response.data.resuelta;
                this.facturadas = response.data.facturada;
                this.despachadas = response.data.despachada;
                this.cerradas = response.data.cerrada;
                this.conNovedad = response.data.novedad;
            } catch (error) {
                this.manejarError(error);
            }
        },
        /**
         * Restablece todos los filtros en uso y lista las solicitudes.
         */
        cleanFiltros() {
            this.buscadorIdsolicitud = null;
            this.buscadorServicio = null;
            this.buscadorDocumento = null;
            this.buscarTipoSolicitud = null;
            this.buscarEstado = null;
            this.buscadorAseguradora = null;
            this.filtrosInternos = false;
            this.$refs.filtroDocumento?.blur();
            this.$refs.filtroTipo?.blur();
            this.$refs.filtroEstado?.blur();
            this.$refs.filtroAseguradora?.blur();
            this.listarSolicitudes();
        },
        /**
         * Abre el diálogo de novedad.
         * @param {Object} item - El objeto que contiene los detalles de la solicitud de novedad. 
         */
        abrirDialogoNovedad(item) {
            this.solicitudNovedad = item;
            this.dialogoNovedad = true;
        },
        /**
         * Cierra el diálogo de novedad.
         */
        cerrarDialogoNovedad() {
            this.solicitudNovedad = {};
            this.dialogoNovedad = false;
            this.documento.adjunto = null;
            setTimeout(() => {
                this.motivoNovedad = null;
                this.$refs.motivoNovedad?.reset();
            }, 300);
        },
        /**
         * Envía una novedad a la solicitud seleccionada.
         */
        novedad() {
            this.inProgress = true;
            const novedad = {
                idSolicitud: this.solicitudNovedad.idSolicitud,
                idMotivo: this.motivoNovedad,
                adjunto: this.documento.adjunto !== null ? this.documento.adjunto.replace(/^data:application\/pdf;base64,/, '') : '',
            }
            this.$http.post(`msa-external/api/solicitudEntrega/novedad`, novedad)
            .then(() => {
                this.inProgress = false;
                this.pagina = 1;
                this.cerrarDialogoNovedad();
                this.formatoIncorrecto.adjunto = null;
                this.$refs.adjuntoFile?.reset();
                this.listarSolicitudes();
            }).catch(error => {
                this.manejarError(error);
                this.inProgress = false;
            });
        },
        /**
         * Muestra un mensaje de error cuando el formato del archivo es incorrecto.
         */
        mostrarMensajeErrorFormatoIncorrecto(type) {
            this.formatoIncorrecto[type] = 'Formato incorrecto, debe subir un archivo pdf o una imagen  .png, .jpg, .jpeg, .tiff';
        },
        /**
         * Carga un documento seleccionado por el usuario y lo muestra en un modal para su confirmación.
         * 
         * @param {string} type - Tipo de documento a cargar ('documento', 'formulaMedica', 'autorizacionMedica', 'cuotaModerada', 'pagoDomicilio').
         */
        cargarDocumento(type) {
            if (!event || !event.target) {
                return;
            }
            const fileInput = event.target;
            if (fileInput && fileInput.files && fileInput.files[0]) {
                const file = fileInput.files[0];

                const extensionesPermitidas = ['pdf', 'jpg', 'png', 'jpeg', 'tiff'];

                if (!file || !extensionesPermitidas.includes(file.name.split('.').pop().toLowerCase())) {
                    this.mostrarMensajeErrorFormatoIncorrecto(type);
                    return;
                }
            
                const reader = new FileReader();
                reader.onload = (event) => {
                    const base64String = event.target.result;
                    if (this.esImagen(base64String) || this.esPDF(base64String)) {
                        this.documento[type] = reader.result;
                        this.formatoIncorrecto[type] = null;
                    } else {
                        this.mostrarMensajeErrorFormatoIncorrecto(type);
                    }
                };
                reader.readAsDataURL(file);
                fileInput.value = null;
            }
        },
        /** Valida que la imagen sea correcta */
        esImagen(base64String) {
            return base64String.startsWith('data:image/jpeg;base64,/9j/') || 
                base64String.startsWith('data:image/jpg;base64,') ||
                base64String.startsWith('data:image/png;base64,iVBORw0KGgo') ||
                base64String.startsWith('data:image/tiff;base64,SUkq');
        },
        /** Valida que el pdf sea correcto */
        esPDF(base64String) {
            if (!base64String.startsWith('data:application/pdf;base64,')) {
                return false;
            }

            const binary = atob(base64String.split(',')[1]);
            const bytes = new Uint8Array(binary.length);
            for (let i = 0; i < binary.length; i++) {
                bytes[i] = binary.charCodeAt(i);
            }

            const pdfText = new TextDecoder().decode(bytes);

            return pdfText.includes('%PDF-');
        },
        /**
         * Borra el documento seleccionado del tipo especificado.
         * 
         * @param {string} type - Tipo de documento a borrar ('documento', 'formulaMedica', 'autorizacionMedica', 'cuotaModerada', 'pagoDomicilio').
         */
        borrarDocumento(type) {
            this.documento[type] = null;
            this.formatoIncorrecto[type] = null;
            this.$refs.adjuntoFile?.reset();
        },
        /**
         * Convierte el Base64 a Blob y lo descarga en PDF
         */
        downloadPdf() {
            const link = document.createElement("a");
            const byteCharacters = atob(this.pdfBase64.split(",")[1]);
            const byteNumbers = new Array(byteCharacters.length).fill(null).map((_, i) => byteCharacters.charCodeAt(i));
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });

            const url = URL.createObjectURL(blob);
            const nombreArchivo = this.nombreArchivoPdf || "documento.pdf";
            link.href = url;
            link.setAttribute("download", nombreArchivo);
            document.body.appendChild(link);
            link.click();

            link.remove();
            URL.revokeObjectURL(url);
        },
        /**
         * Detecta el navegador actual utilizando el userAgent.
         * Convierte el userAgent a minúsculas y busca la cadena "firefox" o "chrome".
         * 
         * @returns {string} El nombre del navegador ('firefox' o 'chrome').
         */
        getBrowser() {
            const userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf("firefox") > -1) {
                return 'firefox';
            } else {
                return 'chrome';
            } 
        },
        /**
         * Renderiza un archivo PDF desde una cadena Base64 en un visor embebido (iframe).
         * Convierte los datos base64 en un Blob de PDF, genera un URL temporal para el visor
         * y lo establece como la fuente (src) del iframe que muestra el PDF.
         * 
         * @param {string} base64Data - La cadena Base64 que representa el archivo PDF.
         */
        async embedPDF(base64Data) {  
            const pdfBlob = this.base64ToArrayBuffer(base64Data);
            const pdfBlobUrl = URL.createObjectURL(pdfBlob);
            const pdfViewer = this.$refs.pdfViewer;
            pdfViewer.src = pdfBlobUrl;
            setTimeout(() => {
                this.pdfEmbedActive = true;
            }, 200);
        },
        /**
         * Convierte una cadena Base64 en un ArrayBuffer y crea un Blob de tipo PDF.
         * Esta función es necesaria para convertir los datos base64 en un formato que
         * los navegadores puedan interpretar como un archivo PDF.
         * 
         * @param {string} base64 - La cadena Base64 que representa el archivo PDF.
         * @returns {Blob} Un Blob de tipo 'application/pdf' listo para ser mostrado en el visor.
         */
        base64ToArrayBuffer(base64) {
            const byteCharacters = atob(base64);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            return new Blob([byteArray], { type: 'application/pdf' });
        },
    },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.fondoDialog {
    background-color: #1867c0;
    color: white;
}
.formulario-flex {
    flex-wrap: nowrap;
}
@media (max-width: 700px) {
    .formulario-flex {
        flex-wrap: wrap !important;
    }
}
::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
::v-deep .elevation .v-data-footer {
    width: 100%;
}
::v-deep .elevation .v-data-footer__select .v-select {
    margin: 5px;
    margin-left: 10px;
}
.leftSide {
    height: 100vh;
    margin-bottom: 20px;
    overflow: auto;
}
.pagination {
    margin-top: 0;
}
.pagination-text {
    font-size: 13px;
}
.v-select .v-input__control, .v-select .v-input__slot {
    font-size: 14px;
}
.boxPdf {
    overflow: auto;
    height: 73vh;
}
.boxPdf>span {
    width: 100%;
}
.pdfClose {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
.pdfClose>p {
    width: 100%
}
.iconPdfIframe {
    zoom: 3;
}
.divCard {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 0px;
}
.divCard>div {
    padding: 0 !important
}
.container {
    padding: 0px;
    margin: 20px 0 0 0;
    max-width: none !important;
    width: 100% !important;
}
.encabezadoCard {
    margin: 0 !important;
}
.encabezadoCard .v-card {
    padding: 3px;
}
.height-table {
    height: 70px;
}
.table {
    border: 1px solid rgb(205, 205, 205) !important;
}
table thead {
    display: block;
    width: 100%;
    background: #c7c4c4;
}
table tbody {
    display: block;
    width: 100%;
    overflow-y: overlay;
    overflow-x: auto;
}
table tbody tr, table thead tr {
    width: 100%;
    display: table;
}
table tbody tr td, table thead tr th {
    display: table-cell;
    padding: 1px 2px;
    overflow: hidden;
    border-right: 1px solid #d4d4d4;
    word-wrap: break-word !important;
    word-break: break-all !important;
}
.tableTrazabilidad tbody tr td, table thead tr th {
    padding: 3px !important;
}
table tbody tr td {
    font-size: 10px !important;
}
table tbody tr td:nth-last-child(1), table thead tr th:nth-last-child(1) {
    border-right: none !important;
}
.alerta {
    display: flex;
    justify-content: center;
    margin-left: 0.3rem;
    margin-top: -0.4rem;
}
.pulse {
    animation: pulse 1s infinite alternate;
}
@keyframes pulse {
    0% {
        transform: scale(1); 
    }
    100% {
        transform: scale(1.5);
    }
}
.card-no-trabajando {
    box-shadow: #1867c0;
}
.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  width: 30vw;
}
.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
  width: 80%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
.advertencia-icon {
  color: #f80505;
}
.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}
@keyframes rotate-notification  {
    0% {
        transform: rotateX(180deg);
    }

    50% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(180deg);
    }
}
.size {
    font-size: xxx-large;
    font-weight: bold;
}
::v-deep .notificacionError .v-data-footer {
  padding-top: 10px;
  justify-content: end;
  border: none;
}
::v-deep .items .v-input__control {
    padding-bottom: 5px !important;
}
::v-deep .items .v-input__control .v-input__slot {
    width: 75%;
}
::v-deep .items .v-input__control .v-input__slot .v-select__selections input {
    padding: 0 !important;
    flex: 1 1 !important;
    width: 0 !important;
    position: fixed !important;
}
::v-deep .items .v-input__control .v-input__slot .v-select__selections .v-select__selection.v-select__selection--comma {
    margin: 0 !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
}
::v-deep .refused .theme--light.v-label{
    color: black !important;
    margin-left: 1rem !important;
}
::v-deep .refused .v-input--selection-controls__ripple {
    height: 0px !important;
    width: 0px !important;
}
::v-deep .refused .v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 28px !important;
}
.rotate-animation {
    animation: rotate 2s linear infinite;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.notificationValidation {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 15vw;
}
.color-domicilio {
    color: rgb(7, 114, 7);
    font-weight: bold;
}
.encabezado {
  background-color: #1867c0;
  color: white;
  padding-top: 10px !important;
}
.cantidad-estados {
    width: 20% !important;
    align-content: center;
    background-color: #d3d3d3;
    border-radius: 0.3em;
}
.div-estados {
    display: flex;
    justify-content: center;
}
.div-estados span{
    color: black;
}
::v-deep .file .v-input__slot {
  padding-left: 0.3rem !important;
} 
::v-deep .file .v-text-field__prefix {
  background-color: #0d48a1dc;
  border-radius: 3px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 0.3rem 0 0.3rem;
  margin-right: 0.3rem;
  color: #ffffff;
}
::v-deep #observacionId {
  margin: 5px;
}
.overlay-derechos {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.notification-derechos {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e3b614;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30vw;
}
.animation-load {
  zoom: 2;
  animation: rotateLoad 2s linear infinite;
}
@keyframes rotateLoad {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.text-load {
    color: #0d47a1;
}
.contenedor-pdf {
    height: 63vh !important;
    overflow: hidden;
    position: relative;
}
.contenedor-pdf > section{
    position: absolute;
    right: 4px;
    z-index: 2;
    width: 140px;
    display: flex;
    justify-content: flex-end;
} 
.pdf-chrome {
    width: 100%;
    height: 63vh;
} 
</style>